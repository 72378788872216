import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import introLogo from '@assets/images/intro_logo.svg';
import introSpeaker from '@assets/images/ic_speaker_none.svg';

const IntroSection = forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <section id="intro" ref={ref} className="flex flex-col items-center w-full px-12 py-6 border-b-[1px] border-b-gray-200 bg-intro-bg sm:py-12 md:py-15 lg:py-24">
      <div className="flex flex-col w-full max-w-[1200px] gap-6 sm:gap-12 md:gap-15 xl:flex-row">
        <div className="flex flex-col flex-wrap flex-none gap-6 sm:gap-12 md:gap-15 ">
          <img src={introLogo} className="w-fit" />
          <div className="flex flex-row max-w-[395px] gap-2 px-5 py-4 text-base text-white rounded-full sm:text-lg bg-custom-personal">
            <img src={introSpeaker} className="w-fit" />
            {t('common.slogan')}
          </div>
        </div>
        <div>
          <div className="mb-4 text-base leading-relaxed text-black sm:text-lg">
            <span className="font-bold text-custom-personal">{t('common.name')}</span>
            {t('home.intro.description1')}
          </div>
          <div className="mb-4 text-base leading-relaxed text-black sm:text-lg">{t('home.intro.description2')}</div>
          <div className="text-base leading-relaxed text-black sm:text-lg">{t('home.intro.description3')}</div>
        </div>
      </div>
    </section>
  );
});

export default IntroSection;
