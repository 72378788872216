import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, replace, useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Apple, Chrome } from 'lucide-react';
import Layout from '@components/account/Layout';
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '@services/authService';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../store/slice/authSlice';
import Alert from '../../components/input/Alert';
import GoogleButton from '../../components/GoogleButton';
import AppleButton from '../../components/AppleButton';
// import KakaoButton from '../../components/KakaoButton';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email(t('account.form.error.email')).required(t('account.form.error.required')),
    password: Yup.string().trim().required(t('account.form.error.required')),
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const result = await login({
        email: data.email,
        password: data.password,
      }).unwrap();

      i18n.changeLanguage(result?.user?.lang_code || 'en');

      if (result.user.is_verified_email) {
        navigate('/guides', { replace: true });
      } else {
        navigate('/verify-email', { replace: true });
      }
    } catch (error) {
      setError('loginfail', {
        code: error.data?.code,
      });
    }
  };

  const handleInputChange = (e) => {
    clearErrors();
    setValue(e.target.name, e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="p-6 overflow-hidden bg-white">
          {/* <div className="relative my-6">
            <hr className="border-gray-300" />
            <span className="absolute px-2 text-gray-500 transform -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2">{t('account.login.or')}</span>
          </div> */}
          <div className="mb-10 text-3xl font-bold">LOGIN</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-7">
              <label htmlFor="login-email" className="block mb-2 font-bold text-black">
                {t('account.form.email')}
              </label>
              <input
                type="email"
                id="login-email"
                {...register('email', {
                  onChange: handleInputChange,
                })}
                className="w-full py-2 border-b-[1px] border-gray-400 focus:outline-none"
              />
              {errors.email && <Alert>{t(`account.form.error.${errors.email.type}`, { field: t('account.form.email') })}</Alert>}
            </div>
            <div className="mb-7">
              <label htmlFor="login-password" className="block mb-2 font-bold text-black">
                {t('account.form.password')}
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="login-password"
                  {...register('password', {
                    onChange: handleInputChange,
                  })}
                  className="w-full py-2 border-b-[1px] border-gray-400 focus:outline-none"
                />
                <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
                  {showPassword ? <EyeOff className="w-5 h-5 text-gray-500" /> : <Eye className="w-5 h-5 text-gray-500" />}
                </button>
              </div>
              {errors.password && <Alert>{t(`account.form.error.${errors.password.type}`, { field: t('account.form.password') })}</Alert>}
              {errors.loginfail && <Alert>{t(`server_errors.${errors.loginfail.code}`)}</Alert>}
            </div>
            <button type="submit" className="w-full px-4 py-4 text-white transition duration-300 rounded-full bg-custom-personal hover:bg-indigo-700">
              {t('account.login.submit')}
            </button>
          </form>

          <div className="mt-6 text-right">
            <Link to="/password-reset-request" className="text-gray-500 hover:text-gray-800">
              {t('account.login.forgotPassword')}
            </Link>
          </div>
          <div className="mt-6 border border-gray-200 border-dashed" />
          <div className="mt-6 mb-6 text-center">
            <p>
              {t('account.login.noAccount')}{' '}
              <Link to="/sign-up" className="font-semibold text-indigo-600 hover:text-indigo-800">
                {t('account.signup.title')}
              </Link>
            </p>
          </div>
          <article>
            <div className="flex gap-2">
              <GoogleButton />
              <AppleButton />
              {/* <KakaoButton /> */}
            </div>
            <p className="mt-2 mb-4 text-xs text-center text-gray-500">
              {t('account.login.termsAgreement')} <br />
              <Link to="/terms-of-service" className="text-indigo-600 hover:text-indigo-800">
                {t('account.login.termsOfService')}
              </Link>{' '}
              {t('account.login.and')}&nbsp;
              <Link to="/privacy-policy" className="text-indigo-600 hover:text-indigo-800">
                {t('account.login.privacyPolicy')}
              </Link>
            </p>
          </article>
        </div>
      </div>
    </Layout>
  );
}

export default Login;
