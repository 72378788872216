import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Layout from '@components/account/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '@store/slice/authSlice';
import { useVerifyEmailMutation, useVerifyEmailRequestMutation } from '../../services/authService';
import confetti from 'canvas-confetti';
import completeIcon from '@assets/images/ic_sign_completed.svg';

const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [isVerified, setIsVerified] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const { user } = useSelector((state) => state.auth);
  const [verifyEmail, { isLoading, isError, error }] = useVerifyEmailMutation();
  const [verifyEmailRequest, { isLoading: isResending }] = useVerifyEmailRequestMutation();

  useEffect(() => {
    if (isVerified) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });

      const timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);

      const redirectTimer = setTimeout(() => {
        dispatch(setUser({ ...user, is_verified_email: true }));
        navigate('/guides', { replace: true });
      }, 5000);

      return () => {
        clearInterval(timer);
        clearTimeout(redirectTimer);
      };
    }
  }, [isVerified, navigate]);

  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .trim()
      .required(t('account.emailVerification.validation.codeRequired'))
      .matches(/^[0-9]{6}$/, t('account.emailVerification.validation.codeInvalid')),
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const code = verificationCode.join('');
    setValue('verificationCode', code);
  }, [verificationCode, setValue]);

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value) && value !== '') return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);
    clearErrors('verificationCode');

    if (value !== '' && index < 5) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && verificationCode[index] === '' && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').trim();
    if (/^[0-9]{6}$/.test(pastedData)) {
      const newCode = pastedData.split('');
      setVerificationCode(newCode);
      setValue('verificationCode', pastedData);
      inputRefs[5].current.focus();
    }
  };

  const onSubmit = async (data) => {
    const code = data.verificationCode;
    if (code.length !== 6) {
      setError('verificationCode', {
        type: 'manual',
        message: t('account.emailVerification.validation.codeInvalid'),
      });
      return;
    }

    try {
      const result = await verifyEmail({
        lang_code: i18n.language,
        code,
      }).unwrap();

      setIsVerified(true);
    } catch (err) {
      // console.error('Failed to verify email:', err);
      setError('verificationCode', {
        type: 'manual',
        message: t('account.emailVerification.validation.verificationFailed'),
      });
    }
  };

  const handleResendCode = async () => {
    try {
      await verifyEmailRequest({ lang_code: i18n.language }).unwrap();
      setVerificationCode(['', '', '', '', '', '']);
      reset();
    } catch (err) {
      console.error('Failed to resend verification code:', err);
    }
  };

  if (isVerified) {
    return (
      <Layout>
        <div className="w-full max-w-md">
          <div className="p-6">
            <div className="flex flex-col items-center animate-scale">
              <h2 className="mb-10 text-3xl font-bold text-black">{t('account.emailVerificationConfirm.title')}</h2>
              <img src={completeIcon} className="w-[138px] mb-10" />
              <p className="text-lg">{t('account.emailVerificationConfirm.successMessage')}</p>
              <p className="mb-10 text-lg">{t('account.emailVerificationConfirm.welcomeMessage')}</p>
              <p
                className="mb-10 text-gray-400"
                dangerouslySetInnerHTML={{
                  __html: t('account.emailVerificationConfirm.redirectMessage', {
                    seconds: `<span class="text-custom-personal">${countdown}</span> `,
                  }),
                }}
              ></p>
            </div>
            <button
              onClick={() => {
                dispatch(setUser({ ...user, is_verified_email: true }));
                navigate('/guides', { replace: true });
              }}
              className="inline-block w-full px-6 py-4 text-lg text-white transition duration-300 bg-indigo-600 rounded-full hover:bg-indigo-700"
            >
              {t('account.emailVerificationConfirm.dashboardButton')}
            </button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="p-6 mb-4">
          <h2 className="mb-10 text-3xl font-bold">{t('account.emailVerification.title')}</h2>
          <p>{t('account.emailVerification.description')}</p>
          {user?.email && (
            <p
              className="mb-10 text-gray-600"
              dangerouslySetInnerHTML={{
                __html: t('account.emailVerification.emailSentTo', { email: `<span class="text-custom-personal">${user.email}</span>` }),
              }}
            />
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-7">
              <label htmlFor="verificationCode" className="block mb-5 font-bold text-black">
                {t('account.emailVerification.codeLabel')}
              </label>
              <div className="flex justify-between mb-7">
                {verificationCode.map((digit, index) => (
                  <input
                    key={index}
                    ref={inputRefs[index]}
                    type="text"
                    maxLength="1"
                    className="w-12 h-12 text-2xl text-center border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                  />
                ))}
              </div>
              {errors.verificationCode && <p className="mt-2 text-sm text-red-600">{errors.verificationCode.message}</p>}
              {isError && <p className="mt-2 text-sm text-red-600">{error?.data?.message || t('account.emailVerification.validation.verificationFailed')}</p>}
            </div>
            <button
              type="submit"
              disabled={isLoading || verificationCode.some((digit) => digit === '')}
              className={`w-full text-lg py-4 px-4 rounded-full transition duration-300 ${isLoading || verificationCode.some((digit) => digit === '') ? 'bg-indigo-400 text-white cursor-not-allowed' : 'bg-indigo-600 text-white hover:bg-indigo-700'}`}
            >
              {isLoading ? t('account.emailVerification.verifying') : t('account.emailVerification.verifyButton')}
            </button>
          </form>

          <div className="flex items-center justify-center gap-2 pt-5 mt-10 border-t border-dashed">
            <p className="text-center text-gray-600 ">{t('account.emailVerification.resendCodeText')}</p>
            <button onClick={handleResendCode} disabled={isResending} className="transition duration-300 text-custom-personal hover:text-indigo-700">
              {isResending ? t('account.emailVerification.resending') : t('account.emailVerification.resendCodeButton')}
            </button>
          </div>
        </div>

        <div className="bg-gray-100 rounded-lg p-7">
          {/* <h2 className="mb-4 text-2xl font-bold">{t('account.signup.process.title')}</h2>
          <ol className="space-y-2 text-gray-700 list-decimal list-inside">
            <li>{t('account.signup.process.step1')}</li>
            <li>{t('account.signup.process.step2')}</li>
            <li>{t('account.signup.process.step3')}</li>
            <li>{t('account.signup.process.step4')}</li>
          </ol> */}
          <p className="text-sm text-gray-600">{t('account.signup.process.noticeStep1')}</p>
          <p
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: t('account.signup.process.noticeStep2', { contact: `<a href="tel:${t('home.contact.tel')}" class="text-custom-personal">${t('home.contact.tel')}</a>` }),
            }}
          ></p>
        </div>
      </div>
    </Layout>
  );
};

export default EmailVerification;
