import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from "@components/manage/Layout";
import { CheckCircle } from 'lucide-react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";

function PaymentSuccessPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="max-w-md mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">
              {t('payment.success.title', '결제 완료')}
            </h1>
            <div className="text-center mb-8">
              <CheckCircle className="mx-auto h-16 w-16 text-green-500 mb-4" />
              <p className="text-xl font-semibold text-gray-800">
                {t('payment.success.message', '결제가 성공적으로 완료되었습니다.')}
              </p>
            </div>
            <div className="space-y-4">
              <Link
                to="/dashboard"
                className="block w-full bg-indigo-600 text-white py-3 px-4 rounded-lg hover:bg-indigo-700 transition duration-300 font-medium text-center"
              >
                {t('payment.success.goToDashboard', '대시보드로 이동')}
              </Link>
              <Link
                to="/"
                className="block w-full bg-gray-200 text-gray-700 py-3 px-4 rounded-lg hover:bg-gray-300 transition duration-300 font-medium text-center"
              >
                {t('payment.success.goToHome', '홈으로 이동')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PaymentSuccessPage;