import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
// import Navigation from '../components/common/Navigation';

function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="font-sans text-gray-800 bg-gray-100 min-h-screen flex flex-col">
      <Header />
      {/* <Navigation /> */}
      <main className="flex-grow container mx-auto px-4 py-8 sm:py-16 flex flex-col items-center justify-center">
        <h1 className="text-6xl font-bold text-indigo-600 mb-4">{t('notFound.title')}</h1>
        <p className="text-xl mb-8 text-center">{t('notFound.message')}</p>
        <Link 
          to="/" 
          className="inline-block bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-indigo-700 hover:to-purple-700 transition duration-300"
        >
          {t('notFound.backToHome')}
        </Link>
      </main>
      <Footer />
    </div>
  );
}

export default NotFound;