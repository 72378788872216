import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setToken, setUser, setLogout, setLastActivity } from "@store/slice/authSlice";
import config from "@config";

const baseQuery = fetchBaseQuery({
  baseUrl: config.API_SERVER_HOST,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 401) {
    // 리프레시 토큰 요청
    const refreshResult = await baseQuery(
      { url: '/auth/refresh-token', method: 'POST' },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      const { accessToken, user } = refreshResult.data;

      // if (!['ADMIN'].includes(user.role)) {
      //   // 관리자가 아닌 경우 로그아웃 처리
      //   const logoutResult = await baseQuery(
      //     {
      //       url: '/auth/logout',
      //       method: 'POST',
      //       headers: { 'Authorization': `Bearer ${accessToken}` },
      //     },
      //     api,
      //     extraOptions
      //   );

      //   if (logoutResult.data) {
      //     api.dispatch(setLogout());
      //     return { error: { status: 401, data: 'Logged out due to insufficient permissions' } };
      //   }
      // }

      // 새 토큰 저장 및 사용자 정보 업데이트
      api.dispatch(setToken(accessToken));
      api.dispatch(setUser(user));

      // 원래 쿼리 재시도
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(setLogout());
    }
  } else if (result.error?.status === 498) {
    api.dispatch(setLogout());
  }

  api.dispatch(setLastActivity(Date.now()));

  return result;
};

export const apiService = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({}),
  refetchOnMountOrArgChange: 1, // 1초 이내에 다시 마운트되면 리페치하지 않음
  refetchOnFocus: false, // 기본적으로 포커스 시 리페치 비활성화
  refetchOnReconnect: true, // 재연결 시 리페치
});

// 미들웨어 설정을 별도로 export
export const apiMiddleware = (getDefaultMiddleware) => 
  getDefaultMiddleware().concat(apiService.middleware);

// 전역 리스너 추가 (선택적)
export const customMiddleware = (storeAPI) => (next) => (action) => {
  return next(action);
};