import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Smartphone, Apple } from 'lucide-react';
import downloadIcon from '@assets/images/download_section.svg';
import contactIcon from '@assets/images/contact_icon.png';
import contactMail from '@assets/images/contact_mail.svg';
import downloadAndroid from '@assets/images/download_android.svg';
import downloadApple from '@assets/images/download_apple.svg';
import arrow from '@assets/images/arrow_upright.svg';
import { Link } from 'react-router-dom';

const DownloadSection = forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <section id="download" ref={ref} className="flex flex-col justify-center w-full px-12 contact:flex-row bg-download-section">
      <div className="flex justify-between flex-col w-full max-w-[1200px] gap-16 xl:flex-row">
        <article className="w-full py-6 sm:py-12 md-py-15 lg:py-24">
          <div className="flex flex-col justify-between gap-10 contact_md:flex-row">
            <div className="flex flex-row items-center text-3xl font-bold text-white">
              <img src={downloadIcon} className="w-[35px]" />
              <span className="text-nowrap">{t('home.download.title')}</span>
            </div>
            <div className="flex flex-row flex-wrap contact:flex-row gap-7">
              <a href="https://apps.apple.com/app/Taphere/id123456789" target="_blank" rel="noopener noreferrer">
                <img src={downloadApple} className="w-full" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.Taphere.app" target="_blank" rel="noopener noreferrer">
                <img src={downloadAndroid} className="w-full" />
              </a>
            </div>
          </div>
        </article>
        {/* <article className="flex flex-col w-full contact:w-[62%] gap-10 py-20 px-12 sm:px-24 md:px-30 contact:pr-48 contact:pl-24 bg-contact-section">
        <div className="flex flex-col gap-10">
          <div className="flex flex-row text-3xl font-bold text-white">
            <span>{t('home.contact.title')}</span>
          </div>
          <div className="flex flex-row flex-wrap justify-between w-full gap-3 gap-y-12 ">
            <div className="flex flex-row">
              <div className="flex items-center gap-7">
                <img src={contactIcon} className="w-[56px] h-[70px]" />
                <div className="flex flex-col gap-4">
                  <div className="text-lg text-gray-500">{t('home.contact.contactTel')}</div>
                  <a href={`tel:${t('home.contact.tel')}`} className="text-3xl font-bold text-white">
                    {t('home.contact.tel')}
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-2 text-lg text-gray-500">
                <img src={contactMail} />
                {t('home.contact.contactEmail')}
              </div>
              <a href={`mailto:${t('home.contact.email')}`} className="text-lg font-bold text-white">
                {t('home.contact.email')}
              </a>
            </div>
            <Link to="/login" className="flex flex-row items-center w-[202px] gap-2 py-2 text-lg font-bold text-center text-white transition duration-300 rounded-full px-7 bg-custom-button hover:bg-indigo-100">
              {t('home.cta.start')}
              <img src={arrow} className="w-6 h-6" />
            </Link>
          </div>
        </div>
      </article> */}
      </div>
    </section>
  );
});

export default DownloadSection;
