export const EMAIL_DOMAINS = [
	'gmail.com',
	'naver.com',
	'daum.net',
	'outlook.com',
	'icloud.com',
];
  
export const LANGUAGES = [
	{ code: 'ko', name: '한국어' },
	{ code: 'zh', name: '中文' },
	{ code: 'ja', name: '日本語' },
	{ code: 'en', name: 'English' }
];
  
export const CATEGORY_IMAGE_MAP = {
	// 'museum': require('@assets/images/museum.jpg'),
  // 'art_gallery': require('@assets/images/museum.jpg'),
  // 'historical_site': require('@assets/images/historicalsite.jpg'),
  // 'nature_and_park': require('@assets/images/naturepark.jpg'),
  // 'city_tour': require('@assets/images/citytour.jpg')
	'museum': require('@assets/images/muse.webp'),
  'art_gallery': require('@assets/images/art.webp'),
  'historical_site': require('@assets/images/ht.webp'),
  'nature_and_park': require('@assets/images/park.webp'),
  'city_tour': require('@assets/images/tour.webp')
};