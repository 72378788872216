import { configureStore } from "@reduxjs/toolkit";
import { apiService, apiMiddleware, customMiddleware } from "../services/api";
import commonReducer from "./slice/commonSlice";
import authReducer from "./slice/authSlice";
import guideReducer from "./slice/guideSlice";

export const store = configureStore({
  reducer: {
    [apiService.reducerPath]: apiService.reducer,
    common: commonReducer,
    auth: authReducer,
    guide: guideReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware()
    .concat(apiService.middleware)  // RTK Query 미들웨어 추가
    .concat(customMiddleware),      // 커스텀 미들웨어 추가 (필요한 경우)
});

export default store;