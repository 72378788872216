import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitialized: true,
  isLoading: false,
  error: null,
  data: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setInitialized(state, action) {
      state.isInitialized = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setInitialized, setLoading, setError, setData } = commonSlice.actions;

export default commonSlice.reducer;

export const selectIsInitialized = (state) => state.common.isInitialized;
export const selectIsLoading = (state) => state.common.isLoading;
export const selectError = (state) => state.common.error;
export const selectData = (state) => state.common.data;