import React, { useEffect, useRef } from 'react';

const AutoResizeTextarea = React.forwardRef(({ value, onChange, ...props }, ref) => {
    const textareaRef = useRef(null);
  
    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
      }
    }, [value]);
  
    return (
      <textarea
        ref={(e) => {
          textareaRef.current = e;
          if (typeof ref === 'function') ref(e);
          else if (ref) ref.current = e;
        }}
        value={value}
        onChange={(e) => {
          onChange(e);
          e.target.style.height = 'auto';
          e.target.style.height = e.target.scrollHeight + 'px';
        }}
        {...props}
      />
    );
  });

export default AutoResizeTextarea;