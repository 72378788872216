import { apiService } from "./api";
import moment from "moment";

export const analysisApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getDailyUsers: builder.query({
      query: () => `/analysis/daily-users`,
      keepUnusedDataFor: 0,
    }),
    getTopGuides: builder.query({
      query: ({date}) => `/analysis/top-guides?date=${date}`,
      keepUnusedDataFor: 0,
    }),
    getOverview: builder.query({
      query: () => `/analysis/overview`,
      keepUnusedDataFor: 0,
    }),
    getGuideDailyUsers: builder.query({
      query: (guideId) => `/analysis/${guideId}/daily-users`,
      keepUnusedDataFor: 0,
    }),
    getGuideOverview: builder.query({
      query: (guideId) => `/analysis/${guideId}/overview`,
      keepUnusedDataFor: 0,
    }),
    getGuideUsers: builder.query({
      query: (guideId) => `/analysis/${guideId}/users`,
      keepUnusedDataFor: 0,
    }),
    getActiveGuides: builder.query({
      query: () => `/analysis/active-guides`,
      keepUnusedDataFor: 0,
    }),
    getTodayStats: builder.query({
      query: () => `/analysis/today-stats`,
      keepUnusedDataFor: 0,
    }),
    getGuideHourlyUsers: builder.query({
      query: () => `/analysis/hourly-users`,
      keepUnusedDataFor: 0,
    }),
    getAnalysisData: builder.query({
      async queryFn(_arg, { dispatch }) {
        try {
          const [dailyUsers, topGuides, overview] = await Promise.all([
            dispatch(analysisApi.endpoints.getDailyUsers.initiate()),
            dispatch(analysisApi.endpoints.getTopGuides.initiate({date:''})),
            dispatch(analysisApi.endpoints.getOverview.initiate())
          ]);
          
          return {
            data: {
              dailyUsers: dailyUsers.data,
              topGuides: topGuides.data,
              overview: overview.data
            }
          };
        }
        catch (error) {
          return { error: `Failed to fetch analysis data: ${error.message}` };
        }
      },
      keepUnusedDataFor: 0,
    }),
    getGuideAnalysisData: builder.query({
      async queryFn(guideId, { dispatch }) {
        try {
          const [dailyUsers, overview, users] = await Promise.all([
            dispatch(analysisApi.endpoints.getGuideDailyUsers.initiate(guideId)),
            dispatch(analysisApi.endpoints.getGuideOverview.initiate(guideId)),
            dispatch(analysisApi.endpoints.getGuideUsers.initiate(guideId))
          ]);
          
          return {
            data: {
              dailyUsers: dailyUsers.data,
              overview: overview.data,
              users: users.data
            }
          };
        } catch (error) {
          return { error: `Failed to fetch guide analysis data: ${error.message}` };
        }
      },
      keepUnusedDataFor: 0,
    }),
    getDashboardData: builder.query({
      async queryFn(_arg, { dispatch }) {
        try {
          const [activeGuides, todayStats, topGuides, hourlyUsers] = await Promise.all([
            dispatch(analysisApi.endpoints.getActiveGuides.initiate()),
            dispatch(analysisApi.endpoints.getTodayStats.initiate()),
            dispatch(analysisApi.endpoints.getTopGuides.initiate({
              date: moment().format('YYYY-MM-DD')
            })),
            dispatch(analysisApi.endpoints.getGuideHourlyUsers.initiate())
          ]);
          
          return {
            data: {
              activeGuides: activeGuides.data,
              todayStats: todayStats.data,
              topGuides: topGuides.data,
              hourlyUsers: hourlyUsers.data
            }
          };
        } catch (error) {
          return { error: `Failed to fetch dashboard data: ${error.message}` };
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetDailyUsersQuery,
  useGetTopGuidesQuery,
  useGetOverviewQuery,
  useGetGuideDailyUsersQuery,
  useGetGuideOverviewQuery,
  useGetGuideUsersQuery,
  useGetActiveGuidesQuery,
  useGetTodayStatsQuery,
  useGetGuideHourlyUsersQuery,
  useGetAnalysisDataQuery,
  useGetGuideAnalysisDataQuery,
  useGetDashboardDataQuery,
} = analysisApi;