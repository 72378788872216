import { apiService } from "./api";

export const privacyPolicyApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getPrivacyPolicy: builder.query({
      query: (language) => `/privacy-policy?language=${language}`,
    }),
    getLatestPrivacyPolicy: builder.query({
      query: (language) => `/privacy-policy/latest?language=${language}`,
    }),
  }),
});

export const {
  useGetPrivacyPolicyQuery,
  useGetLatestPrivacyPolicyQuery,
} = privacyPolicyApi;