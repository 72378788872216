import React, { useRef } from 'react';
import Header from '../components/home/Header';
import Footer from '../components/common/Footer';
import Navigation from '../components/home/Navigation';
import IntroSection from '../components/home/IntroSection';
import BenefitsSection from '../components/home/BenefitsSection';
import ReviewsSection from '../components/home/ReviewsSection';
import PricingSection from '../components/home/PricingSection';
import DownloadSection from '../components/home/DownloadSection';
import LanguageSupportSection from '../components/home/LanguageSupportSection';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Home() {
  const { t } = useTranslation();
  const headerRef = useRef(null);
  const sectionRefs = useRef({});

  const scrollToSection = (key) => {
    const HEADER_HEIGHT = headerRef.current?.offsetHeight;
    const offsetTop = sectionRefs.current[key]?.offsetTop - HEADER_HEIGHT;
    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
  };

  return (
    <div className="max-w-full font-sans text-gray-800 bg-white">
      <Header ref={headerRef} scrollToSection={scrollToSection} />
      <Navigation />
      <main className="flex flex-col items-center w-full">
        <IntroSection ref={(el) => (sectionRefs.current['intro'] = el)} />
        <BenefitsSection ref={(el) => (sectionRefs.current['benefits'] = el)} />
        <ReviewsSection ref={(el) => (sectionRefs.current['reviews'] = el)} />
        <PricingSection ref={(el) => (sectionRefs.current['pricing'] = el)} />
        <LanguageSupportSection />
        <DownloadSection ref={(el) => (sectionRefs.current['download'] = el)} />
      </main>
      <Footer />
    </div>
  );
}

export default Home;
