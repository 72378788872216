import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const MAX_FILE_SIZE = 10 * 1024 * 1024;
const MAX_IMAGES = 10;

const MultiImageUpload = ({ initialImages, onImagesChange, onDeletedImagesChange }) => {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (initialImages && initialImages.length > 0) {
      setImages(initialImages.slice(0, MAX_IMAGES));
    }
  }, [initialImages]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setError('');
      const remainingSlots = MAX_IMAGES - images.length;

      if (remainingSlots <= 0) {
        setError(t('MultiImageUpload.max_images_error', { maxImages: MAX_IMAGES }));
        return;
      }

      const newImages = acceptedFiles
        .slice(0, remainingSlots)
        .filter((file) => {
          if (file.size > MAX_FILE_SIZE) {
            setError(t('MultiImageUpload.file_size_error', { filename: file.name, maxSize: MAX_FILE_SIZE / 1024 / 1024 }));
            return false;
          }
          return true;
        })
        .map((file) => ({
          id: file.name,
          file,
          preview: URL.createObjectURL(file),
          isExisting: false,
        }));

      setImages((prevImages) => {
        const updatedImages = [...prevImages, ...newImages].slice(0, MAX_IMAGES);
        onImagesChange(updatedImages);
        return updatedImages;
      });
    },
    [images.length, onImagesChange, t]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
    },
    multiple: true,
    maxSize: MAX_FILE_SIZE,
  });

  const removeImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      onImagesChange(updatedImages);
      if (prevImages[index].isExisting) {
        onDeletedImagesChange((prev) => [...prev, prevImages[index].id]);
      }
      return updatedImages;
    });
  };

  return (
    <div>
      <div {...getRootProps()} className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ${isDragActive ? 'bg-gray-100' : ''}`}>
        <div className="space-y-1 text-center">
          <svg className="w-12 h-12 mx-auto text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <div className="flex text-sm text-gray-600">
            <input {...getInputProps()} />
            <p className="pl-1">{t('MultiImageUpload.drag_and_drop_or_click')}</p>
          </div>
          <p className="text-xs text-gray-500">
            {t('MultiImageUpload.image_formats')} ({t('MultiImageUpload.max_file_size', { maxSize: MAX_FILE_SIZE / 1024 / 1024 })})
          </p>
          <p className="text-xs text-gray-500">{t('MultiImageUpload.max_images', { maxImages: MAX_IMAGES, currentImages: images.length })}</p>
        </div>
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      {images.length > 0 && (
        <div className="grid grid-cols-2 gap-4 mt-4 sm:grid-cols-3 md:grid-cols-4">
          {images.map((item, index) => (
            <div key={item.id} className="relative">
              <img src={item.preview} alt={`Preview ${index + 1}`} className="object-cover w-full h-24 rounded-md" />
              <button type="button" onClick={() => removeImage(index)} className="absolute p-1 text-white bg-red-500 rounded-full right-1 top-1" aria-label={t('MultiImageUpload.remove_image')}>
                <svg className="w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiImageUpload;
