import { useRoutes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn, selectUser } from './store/slice/authSlice';
import HomePage from './pages/Home';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LoginPage from './pages/account/Login';
import SignupPage from './pages/account/Signup';
import PasswordResetRequest from './pages/account/PasswordResetRequest';
import PasswordReset from './pages/account/PasswordReset';
import Payment from './pages/manage/Payment';
import PaymentSuccessPage from './pages/manage/PaymentSuccessPage';
import PaymentFailurePage from './pages/manage/PaymentFailurePage';
import EmailVerification from './pages/account/EmailVerification';
import EmailVerificationConfirm from './pages/account/EmailVerificationConfirm';
import Dashboard from './pages/manage/Dashboard';
import Guides from './pages/manage/guide/Guides';
import GuideForm from './pages/manage/guide/GuideForm';
import Guide from './pages/manage/guide/Guide';
import GuideAnalysis from './pages/manage/guide/GuideAnalysis';
import GuideView from './pages/GuideViewScreen';
import PoinForm from './pages/manage/guide/point/PointForm';
import Analysis from './pages/manage/Analysis';
import Settings from './pages/manage/Settings';
import NotFound from './pages/NotFound';

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(state => state.auth.user);
  
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  
  if (!user?.is_verified_email) {
    return <Navigate to="/verify-email" />;
  }
  
  return children;
};

const PublicOnlyRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return !isLoggedIn ? children : <Navigate to="/guides" />;
};

const EmailVerificationRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(state => state.auth.user);
  
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  
  if (user?.is_verified_email) {
    return <Navigate to="/guides" />;
  }
  
  return children;
};

export const routes = [
  {
    path: '/',
    element: <HomePage />,
    key: 'home',
    name: 'home'
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />,
    key: 'termsOfService',
    name: 'termsOfService'
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
    key: 'privacyPolicy',
    name: 'privacyPolicy'
  },
  {
    path: '/view',
    element: <GuideView />,
    key: 'guideView',
    name: 'guideView'
  },
  {
    path: '/login',
    element: <PublicOnlyRoute><LoginPage /></PublicOnlyRoute>,
    key: 'login',
    name: 'login'
  },
  {
    path: '/sign-up',
    element: <PublicOnlyRoute><SignupPage /></PublicOnlyRoute>,
    key: 'signup',
    name: 'signup'
  },
  {
    path: '/verify-email',
    element: <EmailVerificationRoute><EmailVerification /></EmailVerificationRoute>,
    key: 'emailVerification',
    name: 'emailVerification'
  },
  // {
  //   path: '/verify-email-confirm',
  //   element: <EmailVerificationConfirm />,
  //   key: 'emailVerificationConfirm',
  //   name: 'emailVerificationConfirm'
  // },
  {
    path: '/password-reset-request',
    element: <PublicOnlyRoute><PasswordResetRequest /></PublicOnlyRoute>,
    key: 'passwordResetRequest',
    name: 'passwordResetRequest'
  },
  {
    path: '/password-reset/:token',
    element: <PublicOnlyRoute><PasswordReset /></PublicOnlyRoute>,
    key: 'passwordReset',
    name: 'passwordReset'
  },
  {
    path: '/dashboard',
    element: <ProtectedRoute><Dashboard /></ProtectedRoute>,
    key: 'dashboard',
    name: 'dashboard'
  },
  {
    path: '/guides',
    element: <ProtectedRoute><Guides /></ProtectedRoute>,
    key: 'guides',
    name: 'guides'
  },
  {
    path: '/guides/new',
    element: <ProtectedRoute><GuideForm /></ProtectedRoute>,
    key: 'newGuide',
    name: 'newGuide',
    message: 'guide.fill_all_fields'
  },
  {
    path: '/guides/:guide_id',
    element: <ProtectedRoute><Guide /></ProtectedRoute>,
    key: 'guide',
    name: 'guide'
  },
  {
    path: '/guides/:guide_id/analysis',
    element: <ProtectedRoute><GuideAnalysis /></ProtectedRoute>,
    key: 'guideAnalysis',
    name: 'guideAnalysis'
  },
  {
    path: '/guides/:guide_id/edit',
    element: <ProtectedRoute><GuideForm /></ProtectedRoute>,
    key: 'editGuide',
    name: 'editGuide',
    message: 'guide.fill_all_fields'
  },
  {
    path: '/guides/:guide_id/points/new',
    element: <ProtectedRoute><PoinForm /></ProtectedRoute>,
    key: 'newPoint',
    name: 'newPoint',
    message: 'guide.fill_all_fields'

  },
  {
    path: '/guides/:guide_id/points/:point_id/edit',
    element: <ProtectedRoute><PoinForm /></ProtectedRoute>,
    key: 'editPoint',
    name: 'editPoint',
    message: 'guide.fill_all_fields'
  },
  {
    path: '/analysis',
    element: <ProtectedRoute><Analysis /></ProtectedRoute>,
    key: 'analysis',
    name: 'analysis'
  },
  {
    path: '/settings',
    element: <ProtectedRoute><Settings /></ProtectedRoute>,
    key: 'settings',
    name: 'settings'
  },
  {
    path: '/settings/payment',
    element: <ProtectedRoute><Payment /></ProtectedRoute>,
    key: 'payment',
    name: 'payment'
  },
  {
    path: '/settings/payment/success',
    element: <ProtectedRoute><PaymentSuccessPage /></ProtectedRoute>,
    key: 'paymentsuccess',
    name: 'payment'
  },
  {
    path: '/settings/payment/failure',
    element: <ProtectedRoute><PaymentFailurePage /></ProtectedRoute>,
    key: 'paymentfailure',
    name: 'payment'
  },
  {
    path: '/not-found',
    element: <NotFound />,
    key: 'notFound',
    name: 'notFound'
  },
  {
    path: '*',
    element: <Navigate to="/not-found" replace />,
    key: 'catch-all',
  }
];

export const useTranslatedRoutes = () => {
  const { t } = useTranslation();
  return routes.map(route => ({
    ...route,
    name: t(`routes.${route.name}`),
    message: route.message ? t(route.message) : null,
  }));
};

function Router() {
  const translatedRoutes = useTranslatedRoutes();

  const element = useRoutes(translatedRoutes);

  return element;
}

export default Router;