import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faLock, faEye, faEyeSlash, faQrcode, faWifi, faShareAlt, faDownload, faExclamationTriangle, faCheckCircle, faShoppingCart, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const ShareModal = ({ shareType, isOpen, onClose, shareCode, guideId, pointId, isPrivate, password, initialTab }) => {
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [activeTab, setActiveTab] = useState(initialTab || 'qr');
  const [nfcStatus, setNfcStatus] = useState('unavailable'); // 'unavailable', 'available', 'writing'
  const qrRef = useRef(null);

  const Url = `${window.location.origin}/view?guide_id=${guideId || ''}&point_id=${pointId || ''}&share_code=${shareCode || ''}`;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setActiveTab(initialTab || 'qr');
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, initialTab]);

  useEffect(() => {
    // Check if NFC is available
    if ('NDEFReader' in window) {
      setNfcStatus('available');
    }
  }, []);

  const copyShareCode = () => {
    navigator.clipboard
      .writeText(shareCode)
      .then(() => {
        alert(t('shareModal.shareCodeCopied'));
      })
      .catch((err) => {
        console.error(t('shareModal.shareCodeCopyFailed'), err);
      });
  };

  const copyUrl = () => {
    navigator.clipboard
      .writeText(Url)
      .then(() => {
        alert(t('shareModal.urlCopied'));
      })
      .catch((err) => {
        console.error(t('shareModal.urlCopyFailed'), err);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector('canvas');
    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'qr-code.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const writeNFC = async () => {
    if (nfcStatus !== 'available') return;

    setNfcStatus('writing');
    try {
      const ndef = new window.NDEFReader();
      await ndef.write({
        records: [{ recordType: 'url', data: Url + '&by=nfc' }],
      });
      alert(t('shareModal.nfcWriteSuccess'));
    } catch (error) {
      console.error('Error writing NFC: ', error);
      alert(t('shareModal.nfcWriteFailed'));
    } finally {
      setNfcStatus('available');
    }
  };

  if (!isOpen && !isClosing) return null;

  // const isGuide = shareType === 'guide';
  const isGuide = pointId ? false : true;
  const titleKey = isGuide ? 'shareModal.guideTitle' : 'shareModal.pointTitle';
  const descriptionKey = isGuide ? 'shareModal.guideDescription' : 'shareModal.pointDescription';

  const renderTabContent = () => {
    switch (activeTab) {
      case 'qr':
        return (
          <div className="flex flex-col items-center px-5 bg-gray-100 py-7" ref={qrRef}>
            <QRCode value={Url + '&by=qr'} size={128} />
            <div className="flex flex-wrap justify-center gap-2 mt-5">
              <button onClick={copyUrl} className="px-3 py-2 font-semibold transition duration-300 border rounded-xl text-custom-personal border-custom-personal">
                <FontAwesomeIcon icon={faCopy} /> {t('shareModal.copyUrl')}
              </button>
              <button onClick={downloadQRCode} className="px-3 py-2 font-semibold transition duration-300 border rounded-xl text-custom-personal border-custom-personal ">
                <FontAwesomeIcon icon={faDownload} /> {t('shareModal.downloadQR')}
              </button>
            </div>
          </div>
        );
      case 'nfc':
        return (
          <div className="flex flex-col items-center px-5 bg-gray-100 py-7">
            {nfcStatus === 'available' && (
              <div className="mb-4">
                <div className="text-green-600">
                  <FontAwesomeIcon icon={faCheckCircle} className="mb-2 text-3xl" />
                  <p>{t('shareModal.nfcAvailable')}</p>
                </div>
              </div>
            )}
            <div className="flex flex-col items-center justify-center gap-5 mb-4">
              <p className="flex items-start text-sm font-semibold text-black">
                <span>{t('shareModal.nfcWriteInstructions')}</span>
              </p>

              {/* <span className="mr-2 text-sm">{t('shareModal.nfcUrl')}:</span> */}
              <div className="flex gap-2">
                <button onClick={copyUrl} className="flex items-center px-3 py-2 text-sm font-semibold transition duration-300 border rounded-md border-custom-personal text-custom-personal">
                  <FontAwesomeIcon icon={faCopy} className="mr-1" />
                  {t('shareModal.copyNfcUrl')}
                </button>
                {nfcStatus === 'available' && (
                  <button onClick={writeNFC} className="flex items-center px-3 py-2 text-sm font-semibold transition duration-300 border rounded-md border-custom-personal text-custom-personal">
                    <FontAwesomeIcon icon={faWifi} className="mr-2" />
                    {t('shareModal.writeNFC')}
                  </button>
                )}
              </div>
            </div>

            {nfcStatus === 'writing' && (
              <div className="mt-4 text-blue-600">
                <FontAwesomeIcon icon={faWifi} className="mb-2 text-3xl animate-pulse" />
                <p>{t('shareModal.nfcWriting')}</p>
              </div>
            )}
            <div className="flex flex-col items-start w-full p-4 bg-white rounded-2xl">
              <h4 className="mb-2 font-bold">{t('shareModal.nfcTagRequest')}:</h4>
              <p className="mb-2 text-sm">{t('shareModal.nfcTagRequestDesc')}</p>
              <ol className="text-sm text-left list-decimal list-inside">
                <li>{t('shareModal.nfcStep1')}</li>
                <li>{t('shareModal.nfcStep2')}</li>
                <li>{t('shareModal.nfcStep3')}</li>
                <li>{t('shareModal.nfcStep4')}</li>
              </ol>
            </div>
            <div className="flex flex-wrap gap-2 mt-4 text-sm">
              {/* <p>{t('shareModal.nfcContact')}</p> */}
              <p className="font-bold">
                {t('shareModal.nfcEmail')} <span className="text-custom-personal">support@taphere.app</span>
              </p>
              <p className="font-bold">
                {t('shareModal.nfcPhone')} <span className="text-custom-personal">{i18n.language === 'ko' ? '062-228-0888' : '+82 (062) 228-0888'}</span>
              </p>
            </div>
          </div>
        );
      case 'code':
        return (
          <div className="flex flex-col items-center px-5 bg-gray-100 py-7">
            <span className="text-2xl font-bold tracking-widest">{shareCode}</span>
            <button onClick={copyShareCode} className="px-3 py-2 mt-5 font-semibold transition duration-300 border rounded-md text-custom-personal border-custom-personal">
              <FontAwesomeIcon icon={faCopy} /> {t('shareModal.copyShareCode')}
            </button>
            {isPrivate && (
              <div className="mt-5 text-center">
                <p className="text-sm text-red-600">
                  <FontAwesomeIcon icon={faLock} className="mr-2" />
                  {t('shareModal.privateGuideInfo')}
                </p>
                <div className="inline-flex items-center justify-center px-2 py-1 mt-5 overflow-hidden bg-white rounded-lg">
                  <span className="mr-2 font-semibold text-gray-500">{t('shareModal.password')}:</span>
                  <div className="relative">
                    <input type={showPassword ? 'text' : 'password'} value={password} readOnly className="px-2 py-1 w-[150px]" />
                    <button onClick={togglePasswordVisibility} className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isClosing ? 'animate-fadeOut' : 'animate-fadeIn'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleBackgroundClick}>
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full ${isClosing ? 'animate-modalOut' : 'animate-modalIn'}`}>
          <div className="p-6 bg-white">
            <div className="sm:flex sm:items-start">
              <div className="w-full mt-3 text-left">
                <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                  {t(titleKey)}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{t(descriptionKey)}</p>
                </div>
                <div className="flex justify-between w-full mt-4 space-x-1">
                  <button onClick={() => setActiveTab('qr')} className={`px-3 py-2 w-full ${activeTab === 'qr' ? 'bg-custom-gradient text-white' : 'bg-gray-200 text-gray-400'}`}>
                    <FontAwesomeIcon icon={faQrcode} /> <span className="hidden sm:inline">{t('shareModal.qrCode')}</span>
                  </button>
                  <button onClick={() => setActiveTab('nfc')} className={`w-full px-3 py-2 ${activeTab === 'nfc' ? 'bg-custom-gradient text-white' : 'bg-gray-200 text-gray-400'}`}>
                    <FontAwesomeIcon icon={faWifi} /> <span className="hidden sm:inline">{t('shareModal.nfc')}</span>
                  </button>
                  {isGuide && (
                    <button onClick={() => setActiveTab('code')} className={`px-3 w-full py-2 ${activeTab === 'code' ? 'bg-custom-gradient text-white' : 'bg-gray-200 text-gray-400'}`}>
                      <FontAwesomeIcon icon={faShareAlt} /> <span className="hidden sm:inline">{t('shareModal.shareCode')}</span>
                    </button>
                  )}
                </div>
                {renderTabContent()}
              </div>
            </div>
          </div>
          <button className="w-full px-4 py-3 font-semibold text-white bg-custom-personal" onClick={handleClose}>
            {t('shareModal.close')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
