import { useState, useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import config from '@config';

const ImageCarousel = ({ images, onImageClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = useCallback(
    (e) => {
      e.stopPropagation();
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    },
    [images.length]
  );

  const prevImage = useCallback(
    (e) => {
      e.stopPropagation();
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    },
    [images.length]
  );

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div className="relative w-full h-48 mb-4">
      <img src={images[currentIndex]?.preview || `${config.API_SERVER_HOST}/files/point/${images[currentIndex].filename}?x=1280x720`} alt={`Image ${currentIndex + 1}`} className="object-cover w-full h-full" onClick={() => onImageClick(images[currentIndex])} />
      {images.length > 1 && (
        <>
          <button onClick={prevImage} className="absolute z-10 p-2 text-white transform -translate-y-1/2 bg-black bg-opacity-50 rounded-full left-2 top-1/2">
            <ChevronLeft size={24} />
          </button>
          <button onClick={nextImage} className="absolute z-10 p-2 text-white transform -translate-y-1/2 bg-black bg-opacity-50 rounded-full right-2 top-1/2">
            <ChevronRight size={24} />
          </button>
          <div className="absolute right-0 flex justify-end space-x-2 transform bottom-2">
            {/* {images.map((_, index) => (
              <div key={index} className={`w-2 h-2 rounded-full ${index === currentIndex ? 'bg-white' : 'bg-gray-400'}`} />
            ))} */}
            <div className="px-2 py-1 mb-2 mr-2 text-gray-400 bg-black rounded-3xl bg-opacity-30">
              <div className="-mb-1">
                <span className="text-white">{currentIndex + 1}</span> / {images.length}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCarousel;
