import React from 'react';

const Alert = ({ 
  children, 
  type = 'info', 
  icon, 
  onClose 
}) => {
  const baseClasses = "p-4 rounded-md mb-4 flex items-start";
  const typeClasses = {
    info: "bg-blue-100 text-blue-800 border border-blue-200",
    success: "bg-green-100 text-green-800 border border-green-200",
    warning: "bg-yellow-100 text-yellow-800 border border-yellow-200",
    error: "bg-red-100 text-red-800 border border-red-200"
  };

  const IconComponent = icon;

  return (
    <div className={`${baseClasses} ${typeClasses[type]}`} role="alert">
      {icon && (
        <div className="mr-3 mt-0.5">
          <IconComponent className="h-5 w-5" />
        </div>
      )}
      <div className="flex-grow">{children}</div>
      {onClose && (
        <button 
          onClick={onClose}
          className="ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 inline-flex h-8 w-8"
        >
          <span className="sr-only">Dismiss</span>
          <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Alert;