import { X } from 'lucide-react';

import config from '@config';

const ImageModal = ({ image, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative w-full h-full">
        <img
          src={image?.preview || `${config.API_SERVER_HOST}/files/point/${image.filename}?x=1280x720`}
          alt="Enlarged image"
          className="w-full h-full object-contain"
        />
        <button
          onClick={onClose}
          className="absolute top-16 right-8 bg-white text-black p-2 rounded-full"
        >
          <X size={24} />
        </button>
      </div>
    </div>
  );
};

export default ImageModal;