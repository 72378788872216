import React from 'react';
import { useTranslation } from 'react-i18next';

const LoadingOverlay = ({ isLoading, message }) => {
  const { t } = useTranslation();

  if (!isLoading) return null;

  const displayMessage = message || t('common.please_wait');

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg flex flex-col items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500 mb-4"></div>
        <p className="text-indigo-500 font-semibold">{displayMessage}</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;