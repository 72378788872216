import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslatedRoutes } from '@/routes';

function Layout({ children, previousPath }) {
  const location = useLocation();
  const routes = useTranslatedRoutes();

  const normalizePath = (path) => {
    return '/' + path.replace(/^\/+|\/+$/g, '').replace(/\/+/g, '/');
  };

  const findRouteMessage = (routes, pathname) => {
    const normalizedPath = normalizePath(pathname);
    
    for (const route of routes) {
      const match = matchPath(route.path, normalizedPath);
      if (match) {
        return route.message;
      }
    }
    
    const firstSegment = normalizedPath.split('/')[1]; // Get the first non-empty segment
    
    for (const route of routes) {
      if (route.path.startsWith(`/${firstSegment}`)) {
        return route.message;
      }
    }
    
    return '';
  }

  function findRouteName(routes, pathname) {
    const normalizedPath = normalizePath(pathname);
    
    for (const route of routes) {
      const match = matchPath(route.path, normalizedPath);
      if (match) {
        return route.name;
      }
    }
    
    const firstSegment = normalizedPath.split('/')[1]; // Get the first non-empty segment
    
    for (const route of routes) {
      if (route.path.startsWith(`/${firstSegment}`)) {
        return route.name;
      }
    }
    
    return 'Not Found';
  }

  return (
    <div className="flex h-screen font-sans bg-gray-100">
      <Sidebar />
      <main className="flex-1 overflow-y-auto">
        <Header title={findRouteName(routes, location.pathname)} message={findRouteMessage(routes, location.pathname)} previousPath={previousPath} />
        {children}
      </main>
    </div>
  );
}

export default Layout;