import React, { createContext, useState, useContext, useEffect } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [shouldCollapse, setShouldCollapse] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1250) {
        // Large viewport
        if (shouldCollapse) {
          setIsCollapsed(false);
        }
        setShouldCollapse(false);
      } else {
        // Small viewport
        if (!shouldCollapse) {
          setIsCollapsed(true);
        }
        setShouldCollapse(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check on component mount

    return () => window.removeEventListener('resize', handleResize);
  }, [shouldCollapse]);

  return <SidebarContext.Provider value={{ isCollapsed, setIsCollapsed }}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => useContext(SidebarContext);
