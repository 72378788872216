import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Layout from '@components/manage/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { useChangePasswordMutation, useUpdateUserMutation } from '../../services/settingsService';
import constants from '@constants';
import Modal from '@components/common/Modal';
import Toast from '@components/common/Toast';
import Helpers from '@helpers';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isSubscribed, setIsSubscribed] = useState(true);
  const user = useSelector((state) => state.auth.user);

  const [changePassword] = useChangePasswordMutation();
  const [updateUser] = useUpdateUserMutation();

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState([false, false, false]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [userTimeZone, setUserTimeZone] = useState(user.timezone || moment.tz.guess());

  useEffect(() => {
    setUserTimeZone(user.timezone || moment.tz.guess());
  }, [user.timezone]);

  const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string().trim().required(t('settings.oldPasswordRequired')),
    newPassword: Yup.string()
      .trim()
      .min(8, t('settings.passwordMinLength'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, t('settings.passwordRequirements'))
      .required(t('settings.newPasswordRequired')),
    confirmPassword: Yup.string()
      .trim()
      .oneOf([Yup.ref('newPassword'), null], t('settings.passwordsMismatch'))
      .required(t('settings.confirmPasswordRequired')),
  });

  const togglePasswordVisibility = (index) => {
    setShowPassword((prev) => {
      const newShowPassword = [...prev];
      newShowPassword[index] = !newShowPassword[index];
      return newShowPassword;
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const handleCancelSubscription = () => {
    setIsConfirmModalOpen(true);
  };

  const confirmCancelSubscription = () => {
    setIsSubscribed(false);
    setIsConfirmModalOpen(false);
    setToastMessage(t('settings.cancelSuccess'));
    setShowToast(true);
  };

  const handleChangeLanguage = async (e) => {
    try {
      i18n.changeLanguage(e.target.value);

      const result = await updateUser({
        lang_code: e.target.value,
      });

      if ('data' in result) {
        setToastMessage(t('settings.languageChanged'));
        setShowToast(true);
      } else if ('error' in result) {
        console.error('Update failed:', result.error);
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const handleChangeTimeZone = async (e) => {
    const newTimeZone = e.target.value;
    setUserTimeZone(newTimeZone);

    try {
      const result = await updateUser({
        timezone: newTimeZone,
      });

      if ('data' in result) {
        setToastMessage(t('settings.timezoneChanged'));
        setShowToast(true);
      } else if ('error' in result) {
        console.error('Update failed:', result.error);
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const onPasswordSubmit = async (data) => {
    try {
      await changePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      setIsPasswordModalOpen(false);
      setToastMessage(t('settings.passwordChangeSuccess'));
      setShowToast(true);
      reset();
    } catch (error) {
      setToastMessage(t('settings.passwordChangeError'));
      setShowToast(true);
    }
  };

  return (
    <Layout>
      <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Account Settings Section */}
        <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-semibold leading-6 text-gray-900">{t('settings.accountSettings')}</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <dt className="w-3/12 text-sm font-medium text-gray-500 sm:w-2/12">{t('settings.email')}</dt>
                <dd className="text-sm text-gray-900">{user.email}</dd>
              </div>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <dt className="w-3/12 text-sm font-medium text-gray-500 sm:w-2/12">{t('settings.password')}</dt>
                <dd className="text-sm text-gray-900">
                  <button className="px-3 py-1 font-semibold transition duration-300 border rounded-full text-custom-personal border-custom-personal " onClick={() => setIsPasswordModalOpen(true)}>
                    {t('settings.changePassword')}
                  </button>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Payment Info Section */}
        <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-semibold leading-6 text-gray-900">{t('settings.paymentInfo')}</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <dt className="w-3/12 text-sm font-medium text-gray-500 sm:w-2/12">{t('settings.currentPlan')}</dt>
                <dd className="mt-1 text-sm text-gray-900">{isSubscribed ? t('settings.proPlan', { price: '29,900' }) : t('settings.freePlan')}</dd>
              </div>
              {isSubscribed && (
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <dt className="w-3/12 text-sm font-medium text-gray-500 sm:w-2/12">{t('settings.nextBillingDate')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">2024년 9월 22일</dd>
                </div>
              )}
              <div className="flex items-center px-4 py-4 sm:px-6">
                <dt className="w-3/12 text-sm font-medium text-gray-500 sm:w-2/12">{t('settings.subscriptionManagement')}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {isSubscribed ? (
                    <>
                      <button onClick={() => navigate('/settings/payment')} className="px-3 py-1 mr-2 font-semibold transition duration-300 border rounded-full text-custom-personal border-custom-personal ">
                        {t('settings.changePlan')}
                      </button>
                      <button onClick={handleCancelSubscription} className="px-3 py-1 font-semibold text-red-600 transition duration-300 border border-red-600 rounded-full">
                        {t('settings.cancelSubscription')}
                      </button>
                    </>
                  ) : (
                    <Link to="/settings/payment" className="px-3 py-1 text-white transition duration-300 bg-indigo-600 rounded-md hover:bg-indigo-700">
                      {t('settings.subscribeToPro')}
                    </Link>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Language Settings Section */}
        <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-semibold leading-6 text-gray-900">{t('settings.languageSettings')}</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <dt className="w-3/12 text-sm font-medium text-gray-500 sm:w-2/12">{t('settings.defaultLanguage')}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <select className="block w-full px-3 py-2 mt-1 bg-white border-b border-gray-300 outline-none sm:text-sm" onChange={handleChangeLanguage} value={i18n.language}>
                    {constants.common.LANGUAGES.map((lang) => (
                      <option key={lang.code} value={lang.code}>
                        {lang.name}
                      </option>
                    ))}
                  </select>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Time Zone Settings Section */}
        <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-semibold leading-6 text-gray-900">{t('settings.timezoneSettings')}</h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <dt className="w-3/12 text-sm font-medium text-gray-500 sm:w-2/12">{t('settings.timezone')}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <select className="block w-full px-3 py-2 mt-1 bg-white border-b border-gray-300 outline-none sm:text-sm" onChange={handleChangeTimeZone} value={userTimeZone}>
                    {moment.tz.names().map((tz) => (
                      <option key={tz} value={tz}>
                        {tz}
                      </option>
                    ))}
                  </select>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Password Change Modal */}
        <Modal
          isOpen={isPasswordModalOpen}
          onClose={() => {
            setIsPasswordModalOpen(false);
            reset();
          }}
          onCloseText={t('common.cancel')}
          onSubmit={handleSubmit(onPasswordSubmit)}
          onSubmitText={t('settings.changePassword')}
          title={t('settings.changePassword')}
        >
          <form onSubmit={handleSubmit(onPasswordSubmit)} className="space-y-4">
            <div>
              <label htmlFor="oldPassword" className="block text-sm font-semibold text-black">
                {t('settings.oldPassword')}
              </label>
              <div className="relative">
                <input type={showPassword[0] ? 'text' : 'password'} id="oldPassword" {...register('oldPassword')} className="block w-full py-2 mt-1 border-b border-gray-300 outline-none sm:text-sm" />
                <button type="button" onClick={() => togglePasswordVisibility(0)} className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FontAwesomeIcon icon={showPassword[0] ? faEyeSlash : faEye} className="text-gray-500" />
                </button>
              </div>
              {errors.oldPassword && <p className="mt-1 text-sm text-red-600">{errors.oldPassword.message}</p>}
            </div>
            <div>
              <label htmlFor="newPassword" className="block text-sm font-semibold text-black">
                {t('settings.newPassword')}
              </label>
              <div className="relative">
                <input type={showPassword[1] ? 'text' : 'password'} id="newPassword" {...register('newPassword')} className="block w-full py-2 mt-1 border-b border-gray-300 outline-none sm:text-sm" />
                <button type="button" onClick={() => togglePasswordVisibility(1)} className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FontAwesomeIcon icon={showPassword[1] ? faEyeSlash : faEye} className="text-gray-500" />
                </button>
              </div>
              {errors.newPassword && <p className="mt-1 text-sm text-red-600">{errors.newPassword.message}</p>}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-semibold text-black">
                {t('settings.confirmPassword')}
              </label>
              <div className="relative">
                <input type={showPassword[2] ? 'text' : 'password'} id="confirmPassword" {...register('confirmPassword')} className="block w-full py-2 mt-1 border-b border-gray-300 outline-none sm:text-sm" />
                <button type="button" onClick={() => togglePasswordVisibility(2)} className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FontAwesomeIcon icon={showPassword[2] ? faEyeSlash : faEye} className="text-gray-500" />
                </button>
              </div>
              {errors.confirmPassword && <p className="mt-1 text-sm text-red-600">{errors.confirmPassword.message}</p>}
            </div>
            {/* <div className="mt-6">
              <button type="submit" className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500">
                {t('settings.changePassword')}
              </button>
            </div> */}
            {/* <button className="w-full px-4 py-3 -m-6 font-semibold text-white bg-custom-personal">{t('shareModal.close')}</button> */}
          </form>
        </Modal>

        {/* Confirm Subscription Cancellation Modal */}
        <Modal isOpen={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)} title={t('settings.confirmCancellation')} onCloseText={t('common.cancel')} onSubmit={confirmCancelSubscription} onSubmitText={t('settings.confirmCancel')}>
          <p>{Helpers.formatMessage(t('settings.cancelConfirmMessage'))}</p>
          {/* <div className="flex justify-end space-x-2">
            <button onClick={() => setIsConfirmModalOpen(false)} className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500">
              {t('common.cancel')}
            </button>
            <button onClick={confirmCancelSubscription} className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500">
              {t('settings.confirmCancel')}
            </button>
          </div> */}
        </Modal>

        {/* Toast Message */}
        <Toast message={toastMessage} isVisible={showToast} onClose={() => setShowToast(false)} />
      </div>
    </Layout>
  );
};

export default Settings;
