import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

const DailyUsersChart = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h3 className="mb-8 text-lg font-semibold">{t('dailyUsers')}</h3>
      </div>
      <div className="overflow-hidden bg-white rounded-lg shadow">
        <div className="p-5">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
                  <stop offset="0%" stopColor="#5BC4FF" />
                  <stop offset="100%" stopColor="#FF5BEF" />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" textAnchor="end" interval={0} tick={{ fontSize: 12 }} />
              <YAxis />
              <Tooltip />
              {/* <Legend /> */}
              <Line type="monotone" dataKey="users" stroke="url(#colorGradient)" strokeWidth={3} dot={{ r: 4, strokeWidth: 2, stroke: '#AE8FF7' }} activeDot={{ r: 6, stroke: '#AE8FF7', strokeWidth: 2, fill: 'white' }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default DailyUsersChart;
