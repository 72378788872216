import React from 'react';
import Header from './Header';
import Footer from '../common/Footer';

function Layout({ children }) {
  return (
    <>
      <Header />

      <main className="flex items-center justify-center flex-grow px-4 py-24">{children}</main>

      <Footer />
    </>
  );
}

export default Layout;
