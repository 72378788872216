const API_SERVER_HOST = process.env.REACT_APP_API_SERVER_HOST;
const WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST; 
const TOSSPAY_CLIENT_SECRET_KEY = process.env.REACT_APP_TOSSPAY_CLIENT_SECRET_KEY;
const TOSSPAY_CLIENT_SECRET_KEY_FOR_WIDGET = process.env.REACT_APP_TOSSPAY_CLIENT_SECRET_KEY_FOR_WIDGET;
const config = {
  API_SERVER_HOST,
  WEBSOCKET_HOST,
  TOSSPAY_CLIENT_SECRET_KEY,
  TOSSPAY_CLIENT_SECRET_KEY_FOR_WIDGET
}

export default config;