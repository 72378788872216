import React from 'react';
import { useTranslation } from 'react-i18next';

const LoadingSpinner = ({ isLoading = true, message }) => {
  const { t } = useTranslation();

  if (!isLoading) return null;

  const displayMessage = message || t('common.please_wait');

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="relative">
        <div className="w-20 h-20 border-purple-200 border-2 rounded-full"></div>
        <div className="w-20 h-20 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
      </div>
      <div className="ml-4 text-xl font-semibold text-purple-700">{displayMessage}</div>
    </div>
  );
};

export default LoadingSpinner;