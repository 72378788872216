import React from 'react';
import { useTranslation } from 'react-i18next';

function Navigation() {
  const { t } = useTranslation();

  return (
    // <nav className="sticky top-0 z-10 bg-white shadow-md">
    //   <div className="container px-4 mx-auto">
    //     <ul className="flex flex-wrap justify-center py-4 space-x-4 sm:space-x-8">
    //       <li><a href="#intro" className="text-gray-600 transition duration-300 hover:text-indigo-600">{t('home.nav.intro')}</a></li>
    //       <li><a href="#benefits" className="text-gray-600 transition duration-300 hover:text-indigo-600">{t('home.nav.benefits')}</a></li>
    //       <li><a href="#reviews" className="text-gray-600 transition duration-300 hover:text-indigo-600">{t('home.nav.reviews')}</a></li>
    //       <li><a href="#pricing" className="text-gray-600 transition duration-300 hover:text-indigo-600">{t('home.nav.pricing')}</a></li>
    //       <li><a href="#download" className="text-gray-600 transition duration-300 hover:text-indigo-600">{t('home.nav.download')}</a></li>
    //     </ul>
    //   </div>
    // </nav>
    null
  );
}

export default Navigation;
