import { apiService } from "./api";
import { selectUser } from "@store/slice/authSlice";
import { setGuides } from "@store/slice/guideSlice";

export const guideApi = apiService.injectEndpoints({
  endpoints: builder => ({
    newGuide: builder.mutation({
      query: (guide) => ({
        url: '/guides',
        method: 'POST',
        body: guide,
      }),
    }),
    getGuides: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
        const { getState } = _queryApi;
        const user = selectUser(getState());
        if (!user) {
          return { error: { status: 401, data: 'User not authenticated' } };
        }
        return baseQuery(`/users/${user.id}/guides?page=${_arg?.page || 1}&limit=${_arg?.limit|| 3}`);
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setGuides(data));
        } catch (error) {
          console.error('getGuides error', error);
        }
      },
    }), 
    trackView: builder.mutation({
      query: (body) => ({
        url: `/tracks/view`,
        method: 'POST',
        body: body,
      }),
    }),
    sendHeartbeat: builder.mutation({
      query: (body) => ({
        url: `/tracks/heartbeat`,
        method: 'POST',
        body: body,
      }),
    }),
    getGuide: builder.query({
      query: ({id, password}) => `/guides/${id}?password=${password}`,
      providesTags: ['Guide'],
    }),
    updateGuide: builder.mutation({
      query: ({ id, guide }) => {
        return {
          url: `/guides/${id}`,
          method: 'PUT',
          body: guide,
        };
      },
      invalidatesTags: ['Guide'],
    }),
    deleteGuide: builder.mutation({
      query: (id) => ({
        url: `/guides/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useNewGuideMutation,
  useGetGuidesQuery,
  useGetGuideQuery,
  useUpdateGuideMutation,
  useDeleteGuideMutation,
  useTrackViewMutation,
  useSendHeartbeatMutation,
} = guideApi;