import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import constants from '@constants';
import logo from '@assets/images/logo.svg';

function Header() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="sticky top-0 z-10 flex justify-center px-12 py-5 text-white bg-black">
      <div className="flex w-full max-w-[1200px]">
        <div className="flex flex-row items-center justify-between w-full gap-3">
          <Link to="/" className="text-center sm:text-left">
            <img src={logo} />
          </Link>
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
            <div className="flex-1 px-1 pb-2 border-b border-b-gray-500">
              <select className="w-full py-1 text-lg font-semibold text-white bg-black " defaultValue={i18n.language} onChange={(e) => changeLanguage(e.target.value)}>
                {constants.common.LANGUAGES.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
