import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppleAuthMutation } from '@services/authService';
import Apple from '@assets/images/apple_login.svg';

function AppleButton() {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const [appleAuth] = useAppleAuthMutation();

  useEffect(() => {
    // AppleID SDK 초기화
    window.AppleID.auth.init({
      clientId: 'app.taphere.web', // 애플에서 발급받은 클라이언트 ID
      scope: 'name email',
      redirectURI: 'https://taphere.app/auth/apple', // 리다이렉트 URI
      state: 'origin:web', // 선택적 상태 값
      usePopup: true, // 팝업 방식으로 로그인
    });
  }, []);

  const handleAppleLogin = () => {
    window.AppleID.auth
      .signIn()
      .then((response) => {
        console.log('Apple login successful', response);
        dispatch(
          appleAuth({
            ...response.authorization,
            lang_code: i18n.language,
          })
        );
        // 로그인 성공 시 처리할 로직
      })
      .catch((error) => {
        console.error('Apple login failed', error);
      });
  };

  return (
    <button className="flex items-center justify-center w-full px-4 py-4 mb-4 text-gray-700 transition duration-300 bg-white border border-gray-300 rounded-lg hover:bg-gray-50" onClick={handleAppleLogin}>
      <img src={Apple} className="w-5 h-5 mr-2" />
      {t('account.login.appleLogin')}
    </button>
  );
}

export default AppleButton;
