import React, { useState, useMemo, useEffect } from 'react';
import Layout from '@components/manage/Layout';
import { useParams, Link, Navigate } from 'react-router-dom';
import { Edit, QrCode, Wifi } from 'lucide-react';
import { useGetGuideQuery } from '@services/guideService';
import { useTranslation } from 'react-i18next';
import GuidePreview from '@components/manage/guide/GuidePreview';
import ShareModal from '@components/manage/ShareModal';
import GuideMetaInfo from '../../../components/manage/guide/GuideMetaInfo';
import constants from '@constants';
import config from '@config';
import LoadingSpinner from '@components/LoadingSpinner';
import icAnalysis from '@assets/images/ic_guide_analysis.svg';
import icShare from '@assets/images/ic_guide_share.svg';
import icEdit from '@assets/images/ic_guide_edit.svg';
import icDelete from '@assets/images/ic_guide_delete.svg';
import icQR from '@assets/images/ic_guide_qr.svg';
import icWIFI from '@assets/images/ic_guide_wifi.svg';

const Guide = () => {
  const { guide_id } = useParams();
  const { t, i18n } = useTranslation();
  const { data: guideData, error, isLoading } = useGetGuideQuery({ id: guide_id }, { refetchOnMountOrArgChange: true });
  const [selectedLang, setSelectedLang] = useState('');
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareModalType, setShareModalType] = useState('qr');

  useEffect(() => {
    if (guideData) {
      if (guideData.support_language.includes(i18n.language)) {
        setSelectedLang(i18n.language);
      } else {
        setSelectedLang(guideData.support_language[0]);
      }
    }
  }, [guideData]);

  const handlePointShareClick = (point, type) => {
    setSelectedPoint(point);
    setShareModalType(type);
    setIsShareModalOpen(true);
  };

  const handleShareClick = () => {
    setIsShareModalOpen(true);
  };

  const handleDeleteClick = () => {
    if (window.confirm(t('guide.deleteConfirm'))) {
      // deleteGuide(guide_id);
    }
  };

  const GuidePoints = ({ guideData, guide_id, t, i18n, onShareClick }) => {
    const getLocalizedContent = useMemo(() => {
      return (point, field) => {
        const currentLang = i18n.language;

        if (point[`${field}_${currentLang}`]) {
          return point[`${field}_${currentLang}`];
        }

        for (const lang of guideData.support_language) {
          if (point[`${field}_${lang}`]) {
            return `${point[`${field}_${lang}`]} (${lang})`;
          }
        }

        return point[`${field}_en`] ? `${point[`${field}_en`]} (en)` : 'N/A';
      };
    }, [guideData.support_language, i18n.language]);

    if (!guideData?.points?.length) {
      return (
        <div className="text-center">
          {/* <MapPin className="w-16 h-16 mx-auto mb-4 text-gray-400" /> */}
          <h3 className="text-2xl font-semibold mb-7 text-custom-personal">{t('guide.noPoints')}</h3>
          <p className="text-gray-500 mb-7">
            {t('guide.addFirstPoint')
              .split('\n')
              .map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
          </p>
          <Link to={`/guides/${guide_id}/points/new`} className="inline-block py-4 text-lg font-bold text-white transition duration-300 rounded-full px-7 bg-custom-personal hover:bg-indigo-700">
            + {t('guide.addFirstPointButton')}
          </Link>
        </div>
      );
    }

    return (
      <>
        <ul className="space-y-4">
          {guideData.points.map((point, index) => (
            <li key={point.id || index} className="pb-2 border-b">
              <div className="flex items-center justify-between">
                <span className="font-bold">
                  {index + 1}. {getLocalizedContent(point, 'name')}
                </span>
                <div className="flex space-x-2">
                  <button onClick={() => onShareClick(point, 'qr')} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                    <img src={icQR} className="w-3 h-3" />
                    {/* QR */}
                  </button>
                  <button onClick={() => onShareClick(point, 'nfc')} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                    <img src={icWIFI} className="w-3 h-3" />
                    {/* NFC */}
                  </button>
                  <Link to={`/guides/${guide_id}/points/${point._id}/edit`} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                    <img src={icEdit} className="w-3 h-3" />
                    {/* {t('common.edit')} */}
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <Link to={`/guides/${guide_id}/points/new`} className="inline-block px-4 py-2 mt-4 text-white transition duration-300 rounded-full bg-custom-gradient hover:bg-indigo-700">
          + {t('guide.addNewPoint')}
        </Link>
      </>
    );
  };

  const handleLanguageChange = (langCode) => {
    setSelectedLang(langCode);
  };

  if (isLoading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <Navigate to="/" />;
  }

  const renderImage = () => {
    const imageUrl = (guideData.cover_image ? `${config.API_SERVER_HOST}/files/guide/${guideData.cover_image.filename}?x=1280x720` : '') || constants.common.CATEGORY_IMAGE_MAP[guideData.category];
    return (
      <div className="relative w-full h-48 max-w-md">
        <img src={imageUrl} className="object-cover w-full h-full rounded-xl" loading="lazy" />
      </div>
    );
  };

  const LanguageSpecificTitles = ({ guideData, selectedLang, onLanguageChange }) => {
    return (
      <div>
        <h2 className="mb-5 font-bold">{t('guide.languageSpecificTitles')}</h2>
        <div className="space-y-5">
          <div className="flex flex-wrap gap-2 mb-4">
            {guideData.support_language.map((langCode) => {
              const language = constants.common.LANGUAGES.find((l) => l.code === langCode)?.name || langCode;
              return (
                <button key={langCode} className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${selectedLang === langCode ? 'bg-custom-gradient text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`} onClick={() => onLanguageChange(langCode)}>
                  {language}
                </button>
              );
            })}
          </div>
          <div className="space-y-5">
            <div>
              <label className="mb-5 font-bold text-black">{t('guide.title')}</label>
              <p className="py-2 text-sm text-gray-900 border-b">{guideData[`title_${selectedLang}`]}</p>
            </div>
            <div>
              <label className="mb-5 font-bold text-black">{t('guide.subtitle')}</label>
              <p className="py-2 text-sm text-gray-900 border-b">{guideData[`subtitle_${selectedLang}`]}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const actionMenuItems = [
    {
      type: 'link',
      href: `/guides/${guide_id}/analysis`,
      icon: icAnalysis,
      text: t('statistics'),
    },
    {
      type: 'button',
      onClick: handleShareClick,
      icon: icShare,
      text: t('share'),
    },
    {
      type: 'link',
      href: `/guides/${guide_id}/edit`,
      icon: icEdit,
      text: t('common.edit'),
    },
    {
      type: 'button',
      onClick: handleDeleteClick,
      icon: icDelete,
      text: t('common.delete'),
      className: 'text-red-700 hover:bg-gray-100 hover:text-red-900',
    },
  ];

  return (
    <Layout>
      <div className="grid grid-cols-1 gap-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8 md:grid-cols-4 2xl:grid-cols-3">
        <div className="col-span-1 md:col-span-2 2xl:col-span-2">
          <div className="p-6 mb-6 bg-white shadow-md rounded-xl">
            <div className="flex items-center justify-between mb-6">
              <GuideMetaInfo guideData={guideData} />
              <div className="grid grid-cols-2 gap-4 text-sm 2xl:grid-cols-4">
                {actionMenuItems.map((item, index) => {
                  switch (item.type) {
                    case 'link':
                      return (
                        <Link to={item.href} key={index} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                          <img src={item.icon} className="w-3 h-3" />
                        </Link>
                      );
                    case 'button':
                      return (
                        <button key={index} onClick={() => item.onClick()} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                          <img src={item.icon} className="w-3 h-3" />
                        </button>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
            <div className="mb-6">
              <h2 className="mb-2 font-semibold text-gray-900">{t('guide.mainImage')}</h2>
              {renderImage()}
            </div>
          </div>
          <div className="mb-6 bg-white shadow-md p-7 rounded-xl">
            <LanguageSpecificTitles guideData={guideData} selectedLang={selectedLang} onLanguageChange={handleLanguageChange} />
          </div>

          <div className="bg-white shadow-md p-7 rounded-xl">
            <h2 className="font-bold mb-7">{t('guide.guidePoints')}</h2>
            {/* <GuidePoints guideData={guideData} guide_id={guide_id} t={t} i18n={i18n}  /> */}
            <GuidePoints guideData={guideData} guide_id={guide_id} t={t} i18n={i18n} onShareClick={handlePointShareClick} />
          </div>
        </div>
        <div className="hidden col-span-1 md:col-span-2 2xl:col-span-1 md:block">
          <div className="sticky top-4">
            <GuidePreview
              guideData={{
                ...guideData,
                image: guideData.cover_image ? `${config.API_SERVER_HOST}/files/guide/${guideData.cover_image.filename}?x=1280x720` : '',
              }}
              selectedLang={selectedLang}
              onLanguageChange={handleLanguageChange}
            />
          </div>
        </div>
      </div>
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
          setSelectedPoint(null);
        }}
        guideId={guideData?._id}
        pointId={selectedPoint?._id}
        shareType="point"
        initialTab={shareModalType}
      />
    </Layout>
  );
};

export default Guide;
