import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Layout from "@components/manage/Layout";
import { XCircle } from 'lucide-react';

function PaymentFailurePage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="max-w-md mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">
              {t('payment.failure.title', '결제 실패')}
            </h1>
            <div className="text-center mb-8">
              <XCircle className="mx-auto h-16 w-16 text-red-500 mb-4" />
              <p className="text-xl font-semibold text-gray-800">
                {t('payment.failure.message', '결제 처리 중 문제가 발생했습니다.')}
              </p>
            </div>
            <div className="space-y-4">
              <Link
                to="/payment"
                className="block w-full bg-indigo-600 text-white py-3 px-4 rounded-lg hover:bg-indigo-700 transition duration-300 font-medium text-center"
              >
                {t('payment.failure.tryAgain', '다시 시도하기')}
              </Link>
              <Link
                to="/"
                className="block w-full bg-gray-200 text-gray-700 py-3 px-4 rounded-lg hover:bg-gray-300 transition duration-300 font-medium text-center"
              >
                {t('payment.failure.goToHome', '홈으로 이동')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PaymentFailurePage;