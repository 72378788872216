import React from 'react';

export const formatMessage = (message) => {
  return message.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== message.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

const timeUnits = {
  'ko': {
    'hour': '시',
    'minute': '분',
    'second': '초'
  },
  'en': {
    'hour': 'hour',
    'minute': 'minute',
    'second': 'second'
  },
  'zh': {
    'hour': '小时',
    'minute': '分钟',
    'second': '秒'
  },
  'ja': {
    'hour': '時',
    'minute': '分',
    'second': '秒'
  }
};

const translateTimeUnit = (unit, language) => {
  if (!timeUnits[language] || !timeUnits[language][unit]) {
    return unit;
  }
  return timeUnits[language][unit];
}

const separateSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return { hours, minutes, seconds };
}

export const formatTime = (totalSeconds, language) => {
  const { hours, minutes, seconds } = separateSeconds(Math.round(totalSeconds));
  const formattedUnits = [];
  
  if (hours > 0) {
    formattedUnits.push(`${hours}${translateTimeUnit('hour', language)}`);
  }
  if (minutes > 0) {
    formattedUnits.push(`${minutes}${translateTimeUnit('minute', language)}`);
  }
  if (seconds > 0) {
    formattedUnits.push(`${seconds}${translateTimeUnit('second', language)}`);
  }
  
  return formattedUnits.join(' ') || `0${translateTimeUnit('second', language)}`;
}

export default {
  formatMessage,
  formatTime
}