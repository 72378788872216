import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import reviewTitle from '@assets/images/icon_none.svg';

const ReviewsSection = forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <section id="reviews" ref={ref} className="flex flex-col items-center w-full px-12 py-6 bg-custom-personal sm:py-12 md-py-15 lg:py-24">
      <div className="flex flex-col max-w-[1200px] gap-6 sm:gap-12 md:gap-15 xl:flex-row">
        <div className="flex items-baseline flex-none gap-4 break-keep">
          <img src={reviewTitle} />
          <span className="w-[165px] mb-6 text-3xl font-bold text-center text-white sm:text-4xl sm:mb-8">{t('home.reviews.title')}</span>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 sm:gap-8">
          <blockquote className="p-6 text-white rounded-lg shadow-lg bg-review-base group hover:bg-white sm:p-8">
            <p className="mb-4 group-hover:text-gray-600">"{t('home.reviews.review1.text')}"</p>
            <footer className="font-semibold group-hover:text-indigo-600">
              <span className="group-hover:hidden">-</span> {t('home.reviews.review1.author')}
            </footer>
          </blockquote>
          <blockquote className="p-6 text-white rounded-lg shadow-lg bg-review-base group hover:bg-white sm:p-8">
            <p className="mb-4 group-hover:text-gray-600">"{t('home.reviews.review2.text')}"</p>
            <footer className="font-semibold group-hover:text-indigo-600">
              <span className="group-hover:hidden">-</span> {t('home.reviews.review2.author')}
            </footer>
          </blockquote>
          <blockquote className="p-6 text-white rounded-lg shadow-lg bg-review-base group hover:bg-white sm:p-8">
            <p className="mb-4 group-hover:text-gray-600">"{t('home.reviews.review3.text')}"</p>
            <footer className="font-semibold group-hover:text-indigo-600">
              <span className="group-hover:hidden">-</span> {t('home.reviews.review3.author')}
            </footer>
          </blockquote>
          <blockquote className="p-6 text-white rounded-lg shadow-lg bg-review-base group hover:bg-white sm:p-8">
            <p className="mb-4 group-hover:text-gray-600">"{t('home.reviews.review4.text')}"</p>
            <footer className="font-semibold group-hover:text-indigo-600">
              <span className="group-hover:hidden">-</span> {t('home.reviews.review4.author')}
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
});

export default ReviewsSection;
