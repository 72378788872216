import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const TopGuidesChart = ({ data }) => {
  const { t, i18n } = useTranslation();

  // Function to get the appropriate title based on the current language
  const getLocalizedTitle = (guide) => {
    const languages = ['ko', 'zh', 'ja', 'en'];
    const currentLang = i18n.language;

    // First, try to get the title in the current language
    if (guide[`title_${currentLang}`]) {
      return guide[`title_${currentLang}`];
    }

    // If not available, try other languages in the specified order
    for (let lang of languages) {
      if (guide[`title_${lang}`]) {
        return guide[`title_${lang}`];
      }
    }

    // If no specific language title is found, return the 'name' field if it exists
    if (guide.name) {
      return guide.name;
    }

    // If all else fails, return a default message
    return t('TopGuidesChart.unavailableTitle');
  };

  // Function to get rank suffix based on the current language
  const getRankSuffix = (rank) => {
    switch (i18n.language) {
      case 'ko':
        return '위';
      case 'zh':
        return '位';
      case 'ja':
        return '位';
      default:
        return ['st', 'nd', 'rd'][rank - 1] || 'th';
    }
  };

  // Add ranking and localized names to the data
  const rankedData = data.map((item, index) => {
    const rank = index + 1;
    const localizedName = getLocalizedTitle(item);
    const colors = ['#E285FF', '#C563E4', '#FFA5CB', '#5E42E8', '#7987FF'];
    return {
      ...item,
      ranking: `${rank}${getRankSuffix(rank)}`,
      localizedName,
      shortName: `${rank}${getRankSuffix(rank)} ${localizedName.substring(0, 10)}...`,
      fill: colors[index % colors.length],
    };
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="p-2 bg-white border border-gray-300 rounded shadow">
          <p className="font-bold">{data.localizedName}</p>
          <p>
            {t('TopGuidesChart.uses')}: {data.uses}
          </p>
        </div>
      );
    }
    return null;
  };

  const maxData = Math.max(...data.map((item) => item.uses));

  const renderColorfulLegendText = (value, entry) => {
    const { color, className } = entry;
    console.log(entry);

    return <span className={className}>{value}</span>;
  };
  return (
    <>
      <div>
        <h3 className="mb-8 text-lg font-semibold">{t('TopGuidesChart.topGuides')}</h3>
      </div>
      <div className="overflow-hidden bg-white rounded-lg shadow">
        {data.length !== 0 ? (
          <div className="p-5">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={rankedData} layout="vertical">
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis type="number" axisLine={false} tick={false} tickLine={false} domain={[0, maxData + 2]} />
                <YAxis type="category" dataKey="ranking" interval={0} width={80} axisLine={false} tick={false} tickLine={false} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="uses" name={t('TopGuidesChart.usageCount')} barSize={12} radius={[0, 10, 10, 0]} margin={{ left: 0 }}>
                  <LabelList dataKey="uses" position="right" fill="#4B5563" formatter={(value) => `${value}회`} style={{ fontWeight: 'bold', fill: '#4B5563' }} />
                </Bar>
                <Legend
                  payload={rankedData.map((item, index) => ({
                    value: item.localizedName,
                    color: ['#E285FF', '#C563E4', '#FFA5CB', '#5E42E8', '#7987FF'][index % 5],
                    className: 'inline-block ml-1 text-black',
                  }))}
                  iconSize={8}
                  layout="vertical"
                  verticalAlign="bottom"
                  wrapperStyle={{ left: 80 }}
                  formatter={renderColorfulLegendText}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="overflow-hidden transition-all duration-300 bg-white rounded-lg shadow">
            <div className="flex p-5">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faExclamationTriangle} className="w-5 h-5 text-red-600" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-gray-500">{t('Dashboard.noPopularGuides')}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TopGuidesChart;
