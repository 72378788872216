import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTranslatedRoutes } from '@/routes';
import { Book, PieChart, Settings, ChevronRight, ChevronLeft, ChartLine } from 'lucide-react';
import { useSidebar } from '@contexts/SidebarContext';
import logo from '@assets/images/logo.svg';

const Sidebar = () => {
  const { t } = useTranslation();
  const routes = useTranslatedRoutes();
  const { isCollapsed, setIsCollapsed } = useSidebar();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const sidebarRoutes = routes
    .filter((route) => ['dashboard', 'guides', 'analysis', 'settings'].includes(route.key))
    .map((route) => ({
      ...route,
      icon: {
        dashboard: PieChart,
        guides: Book,
        analysis: ChartLine,
        settings: Settings,
      }[route.key],
    }));

  const handleToggle = () => {
    if (!isMobile) {
      setIsCollapsed((prev) => !prev);
    }
  };

  const sidebarWidth = isMobile || isCollapsed ? 'w-16' : 'w-64';

  return (
    <aside className={`bg-black text-white h-screen shadow-lg transition-all duration-300 ${sidebarWidth} flex-shrink-0`}>
      <div className="flex items-center justify-between px-6 py-5 h-[70px]">
        <Link to="/guides" className="flex items-center">
          {isMobile ? <h1 className="text-3xl font-bold text-indigo-100">T</h1> : !isCollapsed && <img src={logo} />}
        </Link>
        {!isMobile && (
          <button onClick={handleToggle} className="text-indigo-100 focus:outline-none">
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </button>
        )}
      </div>
      <nav>
        {sidebarRoutes.map((route) => {
          const Icon = route.icon;
          return (
            <NavLink key={route.path} to={route.path} className={({ isActive }) => `flex items-center text-lg py-5 px-6 transition-all duration-200 relative ${isActive ? 'text-white bg-gray-700' : 'text-gray-400 hover:bg-gray-800 hover:text-white'}`}>
              {({ isActive }) => (
                <>
                  <span className={`absolute left-0 top-0 bottom-0 w-1 bg-gray-300 transition-all duration-200 ${isActive ? 'opacity-100' : 'opacity-0'}`} />
                  <Icon className={`${isMobile || isCollapsed ? 'text-lg' : 'mr-3 text-2xl'}`} size={isMobile || isCollapsed ? 24 : 28} />
                  <span className={`font-medium ${isMobile || isCollapsed ? 'hidden' : 'block'}`}>{route.name}</span>
                </>
              )}
            </NavLink>
          );
        })}
      </nav>
    </aside>
  );
};

export default Sidebar;
