import React from 'react';
import { useTranslation } from 'react-i18next';
import languageSpeaker from '@assets/images/ic_speaker_colored.svg';

function LanguageSupportSection() {
  const { t, i18n } = useTranslation();
  const highlightValue = {
    ko: '<span class="text-custom-personal">4개국 이상</span>',
    en: '<span class="text-custom-personal">more than 4 languages</span>',
    ja: '<span class="text-custom-personal">4 つ以上の言語</span>',
    zh: '<span class="text-custom-personal">4种以上的语言</span>',
  }[i18n.language];

  return (
    <section id="language-support" className="flex flex-col items-center w-full gap-6 px-12 py-6 bg-white sm:py-12 md-py-15 lg:py-24">
      <div className="flex flex-col w-full justify-between max-w-[1200px] gap-6 sm:gap-12 md:gap-15 contact_md:flex-row">
        <div className="flex flex-col flex-wrap justify-between gap-6 break-keep">
          <div className="flex flex-row gap-2 px-5 py-2 bg-white border rounded-full sm:text-lg w-fit border-custom-personal text-custom-personal">
            <img src={languageSpeaker} />
            <span className="font-semibold">{t('home.languageSupport.title')}</span>
          </div>
          <div
            className="text-3xl font-bold"
            dangerouslySetInnerHTML={{
              __html: t('home.languageSupport.description', { highlight: highlightValue }),
            }}
          ></div>
        </div>
        <div className="pt-5 text-center bg-white">
          <div className="flex flex-wrap justify-center">
            <div className="w-[120px] h-[120px] p-5 bg-white text-center border border-gray-300 rounded-full -mt-5">
              <span className="text-4xl">🇺🇸</span>
              <p className="mt-2 text-lg font-bold">{t('home.languageSupport.languages.en')}</p>
            </div>
            <div className="w-[120px] h-[120px] p-5 bg-white text-center border border-gray-300 rounded-full -ml-3 -mt-5">
              <span className="text-4xl">🇰🇷</span>
              <p className="mt-2 text-lg font-bold">{t('home.languageSupport.languages.ko')}</p>
            </div>
            <div className="w-[120px] h-[120px] p-5 bg-white text-center border border-gray-300 rounded-full -ml-3 -mt-5">
              <span className="text-4xl">🇨🇳</span>
              <p className="mt-2 text-lg font-bold">{t('home.languageSupport.languages.zh')}</p>
            </div>
            <div className="w-[120px] h-[120px] p-5 bg-white text-center border border-gray-300 rounded-full -ml-3 -mt-5">
              <span className="text-4xl">🇯🇵</span>
              <p className="mt-2 text-lg font-bold">{t('home.languageSupport.languages.ja')}</p>
            </div>
            <div className="w-[120px] h-[120px] p-5 bg-white text-center text-gray-300 border border-gray-300 rounded-full -ml-3 -mt-5">
              <span className="text-4xl">+</span>
              <p className="mt-2 text-lg font-semibold ">{t('home.languageSupport.languages.etc')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LanguageSupportSection;
