import React from 'react';
import { useTranslation } from 'react-i18next';
import { Eye, Lock, Unlock, EyeOff, ToggleRight, ToggleLeft, Clock, MapPin } from 'lucide-react';
import Duration from '@components/common/Duration';

const GuideMetaInfo = ({ guideData }) => {
  const { t } = useTranslation();

  const renderMetaInfo = () => (
    <div className="grid grid-cols-2 gap-4 text-sm 2xl:grid-cols-4">
      <MetaItem
        icon={!guideData?.is_private ? <Unlock className="w-4 h-4" /> : <Lock className="w-4 h-4" />}
        text={!guideData?.is_private ? t('guide.public') : t('guide.private')}
        icColor="text-custom-personal"
        // bgcolor={!guideData?.is_private ? 'bg-green-100' : 'bg-red-100'}
      />
      <MetaItem
        icon={guideData?.active ? <ToggleRight className="w-4 h-4" /> : <ToggleLeft className="w-4 h-4" />}
        text={guideData?.active ? t('guide.active') : t('guide.inactive')}
        icColor="text-custom-personal"
        // bgcolor={guideData?.active ? 'bg-green-100' : 'bg-red-100'}
      />
      <MetaItem
        icon={<Clock className="w-4 h-4" />}
        icColor="text-custom-personal"
        // text={<Duration duration={guideData?.time} />}
        text={guideData?.time}
      />
      <MetaItem icon={<MapPin className="w-4 h-4" />} icColor="text-custom-personal" text={`${guideData?.points.length} ${t('guide.points')}`} />
    </div>
  );

  return renderMetaInfo();
};

const MetaItem = ({ icon, text, color = 'text-gray-500', icColor = 'text-gray-600', bgcolor = '' }) => (
  <div className={`flex items-center justify-start ${color} rounded-full ${bgcolor}`}>
    <span className={`mr-1 ${icColor}`}>{icon}</span>
    <span>{text}</span>
  </div>
);

export default GuideMetaInfo;
