import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Play, Pause, X, AlertTriangle } from 'lucide-react';

const MiniAudioPlayer = ({ audioData, selectedLang, isPlaying, progress, onPlayPause, onClose, isSpeechSynthesisSupported, isView = false }) => {
  const { t } = useTranslation();

  if (!isSpeechSynthesisSupported) {
    return (
      <div className={`bg-yellow-100 border-t border-gray-200 shadow-lg p-4 ${isView ? '' : 'mb-10'} z-50`}>
        <div className="flex items-center">
          <AlertTriangle className="mr-2 text-yellow-500" size={24} />
          <p className="text-sm text-yellow-700">{t('audioPlayer.speech_synthesis_not_supported')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`bg-black border-b border-white p-4 ${isView ? '' : 'mb-10'} z-50`}>
      <div className="flex flex-col">
        <div className="flex items-center w-full gap-7">
          <div className="w-full bg-gray-200 rounded-full h-[5px]">
            <div className="h-full rounded-full bg-custom-personal" style={{ width: `${progress}%` }} />
          </div>
          <div className="flex items-center">
            <button onClick={onPlayPause} className="p-2 mr-3 text-white rounded-full">
              {isPlaying ? <Pause size={24} /> : <Play size={24} />}
            </button>
            <button onClick={onClose} className="p-2 rounded-full">
              <X size={24} color="white" />
            </button>
          </div>
        </div>
        <div className="flex-1 mr-4 ">
          {/* <h3 className="text-lg font-semibold text-gray-800 truncate">{audioData?.[`name_${selectedLang}`]}</h3> */}
          <p className="text-sm text-white">{isPlaying ? t('audioPlayer.now_playing') : t('audioPlayer.stopped')}</p>
        </div>
      </div>
    </div>
  );
};

export default MiniAudioPlayer;
