import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Duration from '@components/common/Duration';

const LocalizedDate = ({ date }) => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);
  return moment(date).format('YYYY-MM-DD HH:mm');
};

const GuideInfo = ({ guide }) => {
  const { t } = useTranslation();

  return (
    <div className="text-sm text-gray-500">
      <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
        <div className="flex items-center space-x-1">
          <span className="font-medium">{t('Last updated')}:&nbsp;</span> <LocalizedDate date={guide.updated} />
        </div>
        <span className="hidden text-gray-200 md:block">|</span>
        <div className="flex items-center mt-1 space-x-1 sm:mt-0">
          <span className="font-medium">{t('Points')}:</span>
          <span className="text-custom-personal">{guide.point_count}</span>
        </div>
        <span className="hidden text-gray-200 md:block">|</span>
        <div className="flex items-center mt-1 space-x-1 sm:mt-0">
          <span className="font-medium">{t('Viewing time')}:</span>
          <span className="text-custom-personal">
            <Duration duration={guide.time} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default GuideInfo;
