import React from 'react';
import { Book, Users, Headphones, Clock, LocateIcon, NotebookPen } from 'lucide-react';

const iconComponents = {
  'fa-map-marked-alt': Book,
  'fa-users': Users,
  'fa-headphones': Headphones,
  user: Users,
  clock: Clock,
  'map-location-dot': NotebookPen,
};

const OverviewCard = ({ icon, title, value, bgColor, iconColor }) => {
  const IconComponent = iconComponents[icon.iconName] || Book;

  return (
    <div className="overflow-hidden bg-white shadow rounded-2xl">
      <div className="p-5">
        <div className="flex items-center">
          <div className={`flex-shrink-0 ${bgColor} rounded-full p-3`}>
            <IconComponent className={`w-6 h-6 ${iconColor || 'text-white'}`} />
          </div>
          <div className="flex-1 w-0 ml-5">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
              <dd className="text-xl font-semibold text-gray-900">{value}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewCard;
