import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import Layout from '@components/account/Layout';
import { useTranslation } from 'react-i18next';
import { useSignupMutation } from '../../services/authService';
import { setCredentials } from '@store/slice/authSlice';
import { useDispatch } from 'react-redux';
import Alert from '../../components/input/Alert';

function SignUp() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [signup, { isLoading, isError, error }] = useSignupMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t('account.form.error.required')),
    email: Yup.string().trim().email(t('account.form.error.email')).required(t('account.form.error.required')),
    password: Yup.string().trim().min(8, t('account.form.error.passwordMin')).required(t('account.form.error.required')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const result = await signup({
        lang_code: i18n.language,
        name: data.name,
        email: data.email,
        password: data.password,
      }).unwrap();

      navigate('/verify-email', { replace: true });
    } catch (err) {
      console.error('Failed to sign up:', err);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="p-6 mb-10">
          <h2 className="mb-10 text-3xl font-bold text-left">{t('account.signup.title')}</h2>

          {isError && <Alert className="mb-4">{error?.data?.message || t('account.signup.genericError')}</Alert>}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-7">
              <label htmlFor="signup-name" className="block mb-2 font-bold text-black">
                {t('account.form.name')}
              </label>
              <input type="text" id="signup-name" {...register('name')} className="w-full py-2 border-b-[1px] border-gray-400 focus:outline-none" />
              {errors.name && <Alert>{t(`account.form.error.${errors.name.type}`, { field: t('account.form.name') })}</Alert>}
            </div>
            <div className="mb-7">
              <label htmlFor="signup-email" className="block mb-2 font-bold text-black">
                {t('account.form.email')}
              </label>
              <input type="email" id="signup-email" {...register('email')} className="w-full py-2 border-b-[1px] border-gray-400 focus:outline-none" />
              {errors.email && <Alert>{t(`account.form.error.${errors.email.type}`, { field: t('account.form.email') })}</Alert>}
            </div>
            <div className="mb-7">
              <label htmlFor="signup-password" className="block mb-2 font-bold text-black">
                {t('account.form.password')}
              </label>
              <div className="relative">
                <input type={showPassword ? 'text' : 'password'} id="signup-password" {...register('password')} className="w-full py-2 border-b-[1px] border-gray-400 focus:outline-none" />
                <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
                  {showPassword ? <EyeOff className="w-5 h-5 text-gray-500" /> : <Eye className="w-5 h-5 text-gray-500" />}
                </button>
              </div>
              {errors.password && <Alert>{errors.password.type === 'min' ? t('account.form.error.passwordMin') : t(`account.form.error.${errors.password.type}`, { field: t('account.form.password') })}</Alert>}
            </div>
            <button type="submit" className="w-full px-4 py-4 text-white transition duration-300 bg-indigo-600 rounded-full mb-7 hover:bg-indigo-700" disabled={isLoading}>
              {isLoading ? t('account.signup.submitting') : t('account.signup.submit')}
            </button>
            <div className="mt-2 text-xs text-center text-gray-500 border-b border-dashed pb-7">
              {t('account.signup.consentMessage')} <br />
              <Link to="/terms-of-service" className="text-indigo-600 hover:text-indigo-800">
                {t('account.signup.termsOfService')}
              </Link>{' '}
              {t('account.signup.and')} &nbsp;
              <Link to="/privacy-policy" className="text-indigo-600 hover:text-indigo-800">
                {t('account.signup.privacyPolicy')}
              </Link>
            </div>
          </form>
          <div className="text-center mt-7">
            <p>
              {t('account.signup.haveAccount')}{' '}
              <Link to="/login" className="font-semibold text-indigo-600 hover:text-indigo-800">
                {t('account.login.title')}
              </Link>
            </p>
          </div>
        </div>

        <div className="bg-gray-100 rounded-lg p-7">
          {/* <h2 className="mb-4 text-2xl font-bold">{t('account.signup.process.title')}</h2>
          <ol className="space-y-2 text-gray-700 list-decimal list-inside">
            <li>{t('account.signup.process.step1')}</li>
            <li>{t('account.signup.process.step2')}</li>
            <li>{t('account.signup.process.step3')}</li>
            <li>{t('account.signup.process.step4')}</li>
          </ol> */}
          <p className="text-sm text-gray-600">{t('account.signup.process.noticeStep1')}</p>
          <p
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: t('account.signup.process.noticeStep2', { contact: `<a href="tel:${t('home.contact.tel')}" class="text-custom-personal">${t('home.contact.tel')}</a>` }),
            }}
          ></p>
        </div>
      </div>
    </Layout>
  );
}

export default SignUp;
