import React, { useState } from 'react';
import Layout from '@components/manage/Layout';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faPlus, faLightbulb, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetGuidesQuery } from '../../../services/guideService';
import GuideItem from '@components/manage/guide/GuideItem';
import ShareModal from '@components/manage/ShareModal';
import Pagination from '@components/common/Pagination';
import LoadingSpinner from '@components/LoadingSpinner';
import icNew from '@assets/images/ic_guide_new.svg';

const Guides = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentGuideData, setCurrentGuideData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const guidesPerPage = 10;

  const {
    data: guidesData,
    error,
    isLoading,
  } = useGetGuidesQuery(
    {
      page: currentPage,
      limit: guidesPerPage,
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleShareClick = (guideId, shareType) => {
    const guide = guidesData.docs.find((g) => g._id === guideId);
    if (guide) {
      setCurrentGuideData({
        _id: guide._id,
        shareCode: guide.share_code,
        isPrivate: guide.is_private,
        password: guide.password,
        shareType: shareType,
      });
      setIsModalOpen(true);
    }
  };

  const renderContent = () => {
    if (!guidesData?.docs || guidesData.docs.length === 0) {
      return (
        <div className="flex justify-center w-full">
          <div className="flex max-w-[420px] flex-col items-center text-center py-14">
            <h2 className="mb-10 text-3xl font-bold text-black">{t('guides.emptyState.title')}</h2>
            <img src={icNew} className="mb-10 w-[138px]" />
            <p className="mb-10 text-lg text-black">
              {t('guides.emptyState.description')
                .split('\n')
                .map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </p>
            <div className="w-full space-y-10">
              <Link to="/guides/new" className="flex items-center justify-center px-6 py-3 text-lg font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-full bg-custom-personal">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                {t('guides.createFirst')}
              </Link>
              <div className="flex flex-col justify-center p-5 text-left border border-gray-300 rounded-2xl">
                <p className="text-sm text-black">
                  <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                  {t('guides.needIdeas')}
                </p>
                <ul className="pl-1 mt-5 text-sm list-disc list-inside text-custom-personal">
                  <li>{t('guides.ideaList.item1')}</li>
                  <li>{t('guides.ideaList.item2')}</li>
                  <li>{t('guides.ideaList.item3')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <ul className="divide-y divide-gray-200">
          {guidesData.docs.map((guide) => (
            <GuideItem key={guide._id} guide={guide} onShareClick={handleShareClick} />
          ))}
        </ul>
      </>
    );
  };

  if (isLoading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <Navigate to="/not-found" />;
  }

  return (
    <Layout>
      <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <div className="flex flex-wrap items-center justify-between px-4 py-5 border-b border-gray-200 gap-y-5 sm:px-6">
            <div
              className="text-base text-gray-500"
              dangerouslySetInnerHTML={{
                __html: t('guides.totalCount', { count: `<span class="text-custom-personal font-bold">${guidesData?.total || 0}</span>` }),
              }}
            ></div>

            {guidesData?.docs?.length > 0 && (
              <Link to="/guides/new" className="inline-flex items-center px-5 py-2 text-base font-medium text-white border border-transparent rounded-full shadow-sm bg-custom-button hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                {t('guides.createNew')}
              </Link>
            )}
          </div>
          {renderContent()}
        </div>
      </div>
      <div>{guidesData?.docs?.length > 0 && <Pagination currentPage={guidesData?.page} totalPages={guidesData?.pages} onPageChange={setCurrentPage} />}</div>
      <ShareModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isPrivate={currentGuideData?.isPrivate}
        password={currentGuideData?.password}
        guideId={currentGuideData?._id}
        shareCode={currentGuideData?.shareCode}
        shareType={currentGuideData?.shareType}
        initialTab={currentGuideData?.shareType} // 'qr', 'nfc', 또는 'code'
      />
    </Layout>
  );
};

export default Guides;
