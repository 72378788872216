import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Layout from '@components/account/Layout';
import { Link } from 'react-router-dom';
import Alert from '../../components/input/Alert';

function PasswordResetRequest() {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required(t('account.passwordResetRequest.validation.emailRequired')).email(t('account.passwordResetRequest.validation.emailInvalid')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    // Here you would implement the password reset request logic
    console.log('Password reset requested for:', data.email);
  };

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="p-6 mb-8">
          <h2 className="mb-10 text-3xl font-bold text-left">{t('account.passwordResetRequest.title')}</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-7">
              <label htmlFor="reset-email" className="block mb-2 font-bold text-black">
                {t('account.passwordResetRequest.email')}
              </label>
              <input type="email" id="reset-email" {...register('email')} className="w-full py-2 border-b-[1px] border-gray-400 focus:outline-none" />
              {errors.email && <Alert>{errors.email.message}</Alert>}
            </div>
            <button type="submit" className="w-full px-4 py-4 text-white transition duration-300 bg-indigo-600 rounded-full hover:bg-indigo-700">
              {t('account.passwordResetRequest.sendResetLink')}
            </button>
          </form>

          <div className="mt-6 text-center">
            <p>
              {t('account.passwordResetRequest.rememberAccount')}{' '}
              <Link to="/login" className="font-semibold text-indigo-600 hover:text-indigo-800">
                {t('account.passwordResetRequest.login')}
              </Link>
            </p>
          </div>
        </div>

        <div className="p-6 overflow-hidden bg-gray-100 rounded-lg">
          <h2 className="mb-4 text-2xl font-bold">{t('account.passwordResetRequest.process.title')}</h2>
          <ol className="space-y-2 text-gray-700 list-decimal list-inside">
            <li>{t('account.passwordResetRequest.process.step1')}</li>
            <li>{t('account.passwordResetRequest.process.step2')}</li>
            <li>{t('account.passwordResetRequest.process.step3')}</li>
            <li>{t('account.passwordResetRequest.process.step4')}</li>
          </ol>
          <p className="mt-4 text-sm text-red-500">{t('account.passwordResetRequest.process.note')}</p>
        </div>
      </div>
    </Layout>
  );
}

export default PasswordResetRequest;
