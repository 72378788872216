// const Alert = ({ children }) => (
//   <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2" role="alert">
//     <span className="block sm:inline">{children}</span>
//   </div>
// );

const Alert = ({ children }) => (
  <p className="text-red-500 text-sm mt-1">
    {children}
  </p>
);

export default Alert;