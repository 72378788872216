import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Layout from '@components/manage/Layout';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetGuideQuery } from '@services/guideService';
import { useNewPointMutation, useUpdatePointMutation, useDeletePointMutation } from '@services/pointService';
import MultiImageUpload from '@components/manage/MultiImageUpload';
import InfoTooltip from '@components/common/InfoTooltip';
import AutoResizeTextarea from '@components/input/AutoResizeTextarea';
import GuidePreview from '@components/manage/guide/GuidePreview';
import config from '@config';
import constants from '@constants';
import LoadingSpinner from '@components/LoadingSpinner';
import LoadingOverlay from '@components/common/LoadingOverlay';
import { ToggleRight } from 'lucide-react';
import icSpeaker from '@assets/images/ic_speaker_colored.svg';

const PointForm = () => {
  const { guide_id, point_id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { data: guideData, error, isLoading } = useGetGuideQuery({ id: guide_id }, { refetchOnMountOrArgChange: true });
  const [selectedLang, setSelectedLang] = useState('');
  const [newPoint] = useNewPointMutation();
  const [updatePoint] = useUpdatePointMutation();
  const [deletePoint] = useDeletePointMutation();

  const [images, setImages] = useState([]);
  const [deletedImageIds, setDeletedImageIds] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSpeechSynthesisSupported, setIsSpeechSynthesisSupported] = useState(true);
  const [currentSpeakingLang, setCurrentSpeakingLang] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const synth = window.speechSynthesis;
  const utteranceRef = useRef(null);

  useEffect(() => {
    if (!('speechSynthesis' in window)) {
      setIsSpeechSynthesisSupported(false);
    }

    return () => {
      if (isSpeechSynthesisSupported && utteranceRef.current) {
        synth.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (guideData) {
      if (guideData.support_language.includes(i18n.language)) {
        setSelectedLang(i18n.language);
      } else {
        setSelectedLang(guideData.support_language[0]);
      }
    }
  }, [guideData]);

  const validationSchema = Yup.object().shape({
    support_language: Yup.array().min(1, t('point.validation.language_required')),
    status: Yup.string().trim().oneOf(['published', 'hidden'], t('point.validation.status_invalid')).required(t('point.validation.status_required')),
    ...constants.common.LANGUAGES.reduce(
      (acc, lang) => ({
        ...acc,
        [`name_${lang.code}`]: Yup.string()
          .trim()
          .when('support_language', {
            is: (val) => val && val.includes(lang.code),
            then: (schema) => schema.required(t('point.validation.title_required')),
          }),
        [`description_${lang.code}`]: Yup.string()
          .trim()
          .when('support_language', {
            is: (val) => val && val.includes(lang.code),
            then: (schema) => schema.required(t('point.validation.content_required')),
          }),
        [`audio_script_${lang.code}`]: Yup.string()
          .trim()
          .when('support_language', {
            is: (val) => val && val.includes(lang.code),
            then: (schema) => schema.required(t('point.validation.script_required')),
          }),
      }),
      {}
    ),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      support_language: [],
      status: 'published',
    },
  });

  const point = watch();

  useEffect(() => {
    if (guideData) {
      const pointData = guideData.points.find((point) => point._id === point_id);
      if (pointData) {
        reset({
          support_language: guideData.support_language,
          images: pointData.images,
          status: pointData.status || 'published',
          ...constants.common.LANGUAGES.reduce(
            (acc, lang) => ({
              ...acc,
              [`name_${lang.code}`]: pointData[`name_${lang.code}`],
              [`description_${lang.code}`]: pointData[`description_${lang.code}`],
              [`audio_script_${lang.code}`]: pointData[`audio_script_${lang.code}`],
            }),
            {}
          ),
        });

        if (pointData.images) {
          setImages(
            pointData.images.map((image) => ({
              id: image.id || image._id,
              preview: `${config.API_SERVER_HOST}/files/point/${image.filename}?x=1280x720`,
              isExisting: true,
            }))
          );
        }
      } else {
        reset({ support_language: guideData.support_language, status: 'published' });
      }
    }
  }, [guideData, reset, point_id]);

  useEffect(() => {
    setValue('images', images);
  }, [images]);

  const watchLanguages = watch('support_language', []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('guide_id', guide_id);

      Object.keys(data).forEach((key) => {
        if (key.startsWith('images')) {
          return;
        } else if (Array.isArray(data[key])) {
          data[key].forEach((value) => formData.append(`${key}[]`, value));
        } else {
          formData.append(key, data[key]);
        }
      });

      images.forEach(async (image) => {
        if (!image.isExisting) {
          formData.append(`images`, image.file);
        }
      });

      deletedImageIds.forEach((id) => {
        formData.append(`deleted_image_ids[]`, id);
      });
      if (point_id) {
        const result = await updatePoint({ id: point_id, point: formData });
        console.log(result);
      } else {
        await newPoint(formData);
      }
      navigate(`/guides/${guide_id}`, { replace: true });
    } catch (error) {
      console.error('Failed to save point:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeletePoint = async () => {
    try {
      await deletePoint(point_id).unwrap();
      navigate(`/guides/${guide_id}`, { replace: true });
    } catch (error) {
      console.error('Failed to delete point:', error);
    }
  };

  const speakText = (text, langCode) => {
    if (!isSpeechSynthesisSupported) return;

    synth.cancel();

    if (isSpeaking && currentSpeakingLang === langCode) {
      setIsSpeaking(false);
      return;
    }

    utteranceRef.current = new SpeechSynthesisUtterance(text);
    utteranceRef.current.lang = langCode;

    utteranceRef.current.onstart = () => {
      setIsSpeaking(true);
      setCurrentSpeakingLang(langCode);
      handleLanguageChange(langCode);
    };
    utteranceRef.current.onend = () => {
      setCurrentSpeakingLang(null);
      setIsSpeaking(false);
    };
    utteranceRef.current.onerror = () => {
      setCurrentSpeakingLang(null);
      setIsSpeaking(false);
    };

    setTimeout(() => {
      synth.speak(utteranceRef.current);
    }, 100);
  };

  if (isLoading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return (
      <Layout>
        <div className="py-10 text-center">
          <p>{t('common.error_occurred')}</p>
        </div>
      </Layout>
    );
  }

  const handleLanguageChange = (langCode) => {
    setSelectedLang(langCode);
  };

  const getSubmitMessage = () => {
    if (point_id) {
      return t('point.updating_point');
    } else {
      return t('point.creating_point');
    }
  };

  return (
    <Layout previousPath={`/guides/${guide_id}`}>
      <LoadingOverlay isLoading={isSubmitting} message={getSubmitMessage()} />
      <div className="grid grid-cols-1 gap-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8 md:grid-cols-4 2xl:grid-cols-3">
        <div className="col-span-1 md:col-span-2 2xl:col-span-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-6 bg-white rounded-lg shadow-md mb-7">
              <div>
                <label className="flex items-center text-sm font-bold text-black">
                  {t('point.detailed_images')}
                  <InfoTooltip content={t('point.detailed_images_info')} />
                </label>
                <MultiImageUpload initialImages={images} onImagesChange={setImages} onDeletedImagesChange={setDeletedImageIds} />
              </div>
            </div>

            {/* <div className="mb-4">
                <label className="flex items-center gap-1 mb-1 font-semibold text-black">
                  <ToggleRight/>
                  {t('point.active')}
                </label>
                <select
                  {...register("status")}
                  className="w-full py-2 pl-3 pr-10 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="true">{t('point.status_published')}</option>
                  <option value="false">{t('point.status_hidden')}</option>
                </select>
                {errors.status && <p className="mt-2 text-sm text-red-600">{errors.status.message}</p>}
              </div> */}
            <div className="p-6 bg-white rounded-lg shadow-md">
              {watchLanguages.length > 0 && (
                <div className="space-y-6">
                  {constants.common.LANGUAGES.filter((lang) => watchLanguages.includes(lang.code)).map((lang, index) => (
                    <div key={lang.code} className={`pb-6 ${index !== watchLanguages.length - 1 ? 'border-b border-gray-300' : ''}`}>
                      <div className="flex items-center gap-2 mb-4 ">
                        <div className="flex items-center justify-center w-8 h-8 text-sm text-white bg-black rounded-full">{lang.code.toUpperCase()}</div>
                        <h4 className="text-lg font-bold text-gray-900">{lang.name}</h4>
                      </div>
                      <div className="mb-4">
                        <label htmlFor={`name_${lang.code}`} className="flex items-center text-sm font-bold text-black">
                          {t('point.point_title')}
                          <InfoTooltip content={t('point.point_title_info')} />
                        </label>
                        <input
                          type="text"
                          id={`name_${lang.code}`}
                          {...register(`name_${lang.code}`, {
                            onChange: () => {
                              if (lang.code !== selectedLang) {
                                setSelectedLang(lang.code);
                              }
                            },
                          })}
                          className="w-full py-1 mt-4 border-b border-gray-300 focus:outline-none"
                          placeholder={t(`point.placeholders.point_title.${lang.code}`)}
                        />
                        {errors[`name_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`name_${lang.code}`].message}</p>}
                      </div>
                      <div className="mb-4">
                        <label htmlFor={`description_${lang.code}`} className="flex items-center text-sm font-bold text-black">
                          {t('point.content_display')}
                          <InfoTooltip content={t('point.content_display_info')} />
                        </label>
                        <AutoResizeTextarea
                          id={`description_${lang.code}`}
                          {...register(`description_${lang.code}`, {
                            onChange: () => {
                              if (lang.code !== selectedLang) {
                                setSelectedLang(lang.code);
                              }
                            },
                          })}
                          className="w-full px-4 py-4 mt-4 leading-loose bg-gray-100 outline-none resize-none"
                          placeholder={t(`point.placeholders.point_content.${lang.code}`)}
                        ></AutoResizeTextarea>
                        {errors[`description_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`description_${lang.code}`].message}</p>}
                      </div>
                      <div className="mb-4">
                        <div className="flex justify-between">
                          <label htmlFor={`audio_script_${lang.code}`} className="flex items-center text-sm font-bold text-black">
                            {t('point.script_tts')}
                            <InfoTooltip content={t('point.script_tts_info')} />
                          </label>
                          <button
                            type="button"
                            onClick={() => speakText(watch(`audio_script_${lang.code}`), lang.code)}
                            className={`flex h-[27px] items-center gap-1 border border-custom-personal px-2 text-base font-semibold rounded-full whitespace-nowrap justify-center ${
                              isSpeaking && currentSpeakingLang === lang.code ? 'text-red-600 border-red-600' : 'text-custom-personal border-custom-personal'
                            }`}
                          >
                            {(currentSpeakingLang == null || currentSpeakingLang !== lang.code) && <img src={icSpeaker} />}

                            {isSpeaking && selectedLang === lang.code ? t('point.tts.stop') : t('point.tts.speak')}
                          </button>
                        </div>
                        <div className="flex items-start">
                          <AutoResizeTextarea
                            id={`audio_script_${lang.code}`}
                            {...register(`audio_script_${lang.code}`, {
                              onChange: () => {
                                if (lang.code !== selectedLang) {
                                  setSelectedLang(lang.code);
                                }

                                if (isSpeaking && currentSpeakingLang === lang.code) {
                                  synth.cancel();
                                  setIsSpeaking(false);
                                  setCurrentSpeakingLang(null);
                                }
                              },
                            })}
                            className="w-full px-4 py-4 mt-4 leading-loose bg-gray-100 outline-none resize-none"
                            placeholder={t(`point.placeholders.point_script.${lang.code}`)}
                          ></AutoResizeTextarea>
                        </div>
                        {errors[`audio_script_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`audio_script_${lang.code}`].message}</p>}
                        <p className="mt-2 text-sm text-gray-500">{t('point.tts.device_difference_notice')}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex justify-center mt-6">
              <div className="flex flex-wrap justify-center gap-2">
                {point_id && (
                  <button type="button" onClick={() => setShowDeleteConfirm(true)} className="inline-flex justify-center py-2.5 text-sm font-medium text-white bg-red-600 border border-transparent rounded-full shadow-sm px-7 hover:bg-red-700">
                    {t('point.delete_point')}
                  </button>
                )}
                <button type="submit" className="flex items-center px-10 py-2.5 text-sm font-medium text-center text-white border border-transparent rounded-full shadow-sm bg-custom-personal hover:bg-indigo-700 ">
                  {point_id ? t('point.update_point') : t('point.create_point')}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="hidden col-span-1 md:col-span-2 2xl:col-span-1 md:block">
          <div className="sticky top-4">
            <GuidePreview guideData={guideData} point={point} selectedLang={selectedLang} onLanguageChange={handleLanguageChange} />
          </div>
        </div>
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3">
                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                      {t('point.confirm_delete')}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{t('point.delete_warning')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <button type="button" className="w-1/2 py-3 text-white bg-gray-500" onClick={() => setShowDeleteConfirm(false)}>
                  {t('common.cancel')}
                </button>
                <button type="button" className="w-1/2 py-3 text-white bg-red-600" onClick={handleDeletePoint}>
                  {t('common.delete')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default PointForm;
