import React from 'react';
import { useLogoutMutation } from '../../services/authService';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Header = ({ title, message, homePath, previousPath }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [logout, { isLoading }] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      navigate('/login', { replace: true });
    } catch (err) {
      console.error('Failed to log out:', err);
    }
  };

  const handleBack = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);

    if (previousPath) {
      navigate(previousPath, { replace: true });
    } else if (pathSegments.length > 1) {
      const parentPath = '/' + pathSegments.slice(0, -1).join('/');
      navigate(parentPath, { replace: true });
    } else if (location.pathname !== homePath) {
      navigate(homePath, { replace: true });
    } else if (window.history.length > 2 && document.referrer.includes(window.location.host)) {
      navigate(-1, { replace: true });
    }
  };

  return (
    <header className="bg-white shadow">
      <div className={`max-w-7xl mx-auto h-[70px] py-3.5 px-4 sm:px-6 lg:px-8 flex justify-between items-center`}>
        <div className="flex items-center text-3xl font-bold text-gray-900">
          {location.pathname.split('/').filter(Boolean).length > 1 && <ChevronLeft className="mr-2 cursor-pointer w-9 h-9" onClick={handleBack} />}
          {/* {message ? (
            <div className="mx-auto max-w-7xl">
              <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
              <p className="text-sm text-gray-500">{message}</p>
            </div>
          ) : (
            <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
          )} */}
          <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
        </div>
        <button onClick={handleLogout} className="px-5 py-2 font-bold text-white transition duration-300 bg-black rounded-full hover:bg-gray-600">
          {/* {isLoading ? '로그아웃 중...' : '로그아웃'} */}
          {t('common.logout')}
        </button>
      </div>
    </header>
  );
};

export default Header;
