import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="flex flex-col justify-center w-full gap-8 px-12 py-12 text-white bg-black contact:flex-row ">
      <div className="flex justify-between flex-col w-full max-w-[1200px] gap-16 xl:flex-row">
        <div className="flex flex-col gap-2 text-gray-300 break-keep">
          <div>
            {t('common.footer.company.title')}: {t('common.footer.company.name')} | {t('common.footer.address.title')}: {t('common.footer.address.name')} | {t('common.footer.president.title')}: {t('common.footer.president.name')}
          </div>
          <div>
            {t('common.footer.contact.title')}: <a href={`tel:${t('home.contact.tel')}`}>{t('home.contact.tel')}</a> | {t('common.footer.email.title')}: <a href={`mailto:${t('home.contact.email')}`}>{t('home.contact.email')}</a>
          </div>
          <div className="mb-4">{t('common.footer.copyright')}</div>
        </div>
        <nav className="flex justify-center space-x-4">
          <Link to="/terms-of-service" className="transition-colors duration-300 hover:text-lime-300">
            {t('common.footer.termsOfService')}
          </Link>
          <span className="text-gray-500">|</span>
          <Link to="/privacy-policy" className="transition-colors duration-300 hover:text-lime-300">
            {t('common.footer.privacyPolicy')}
          </Link>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
