import { apiService } from "./api";
import { selectUser } from "@store/slice/authSlice";

export const settingsApi = apiService.injectEndpoints({
  endpoints: builder => ({
    changePassword: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
        const { getState } = _queryApi;
        const user = selectUser(getState());
        
        if (!user) {
          return { error: { status: 401, data: 'User not authenticated' } };
        }

        return baseQuery({
          url: `/users/${user.id}/change-password`,
          method: 'PUT',
          body: _arg,
        });
      },
    }),
    updateUser: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
        const { getState } = _queryApi;
        const user = selectUser(getState());
        
        if (!user) {
          return { error: { status: 401, data: 'User not authenticated' } };
        }

        return baseQuery({
          url: `/users/${user.id}`,
          method: 'PUT',
          body: _arg,
        });
      },
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useUpdateUserMutation,
} = settingsApi;