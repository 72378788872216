import React from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex items-center justify-center my-6">
      <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className="w-10 h-10 mr-2 text-gray-600 rounded-full disabled:opacity-50 hover:bg-gray-100">
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      {pageNumbers.map((number) => (
        <button key={number} onClick={() => onPageChange(number)} className={`mx-1 w-10 h-10 rounded-full ${currentPage === number ? 'bg-indigo-600 text-white' : 'text-gray-600 hover:bg-gray-100'}`}>
          {number}
        </button>
      ))}

      <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} className="w-10 h-10 ml-2 text-gray-600 rounded-full disabled:opacity-50 hover:bg-gray-100">
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

export default Pagination;
