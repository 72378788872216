import React, { useState, useEffect, useMemo } from 'react';
import Layout from '@components/manage/Layout';
import OverviewCard from '@components/manage/analysis/OverviewCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faUsers, faClock, faExclamationTriangle, faTrophy, faUser } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useGetDashboardDataQuery } from '@services/analysisService';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '@components/LoadingSpinner';
import Helpers from '@helpers';

const getLocalizedText = (obj, language, prefix = 'title', fallbackOrder = ['en', 'ko', 'ja', 'zh']) => {
  if (obj[`${prefix}_${language}`]) {
    return obj[`${prefix}_${language}`];
  }

  for (let lang of fallbackOrder) {
    if (obj[`${prefix}_${lang}`]) {
      return obj[`${prefix}_${lang}`];
    }
  }

  if (obj[prefix]) {
    return obj[prefix];
  }

  return Object.values(obj).find((val) => typeof val === 'string') || '';
};

const ActiveGuideCard = ({ guide, onCardClick }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const title = getLocalizedText(guide, currentLanguage);

  return (
    <div className="overflow-hidden transition-all duration-300 bg-white rounded-lg shadow cursor-pointer hover:shadow-lg" onClick={() => onCardClick(guide)}>
      <div className="p-5">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <h3 className="text-lg font-medium text-gray-900 truncate">{title}</h3>
            <p className="mt-1 text-sm text-gray-500">
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              {guide.users} 명 사용 중
            </p>
          </div>
          <div className="flex-shrink-0 ml-4">
            <div className="p-3 bg-indigo-500 rounded-full">
              <FontAwesomeIcon icon={faMapMarkedAlt} className="w-6 h-6 text-white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TopGuideCard = ({ guide, rank }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const title = getLocalizedText(guide, currentLanguage);

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0 p-3 bg-indigo-500 rounded-full">
            <FontAwesomeIcon icon={faTrophy} className="w-6 h-6 -mb-1 text-white" />
          </div>
          <div className="flex-1 w-0 ml-5">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">
                {rank}위 - {title}
              </dt>
              <dd className="text-lg font-medium text-gray-900">{guide.users} 회 사용</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

const GuideModal = ({ guide, onClose }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  if (!guide) return null;

  const title = getLocalizedText(guide, currentLanguage);

  return (
    <div className="fixed inset-0 w-full h-full overflow-y-auto bg-gray-600 bg-opacity-50" onClick={onClose}>
      <div className="relative p-5 mx-auto bg-white border rounded-md shadow-lg top-20 w-96" onClick={(e) => e.stopPropagation()}>
        <div className="mt-3 text-center">
          <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
          <div className="py-3 mt-2 px-7">
            <p className="text-sm text-gray-500">
              {t('Dashboard.totalUsers')}: {guide.users}명
            </p>
            {guide.points && guide.points.length > 0 && (
              <>
                <h4 className="mt-4 mb-2 font-medium text-gray-900 text-md">{t('Dashboard.pointList')}:</h4>
                <ul className="space-y-2">
                  {guide.points.map((point, index) => {
                    const pointName = getLocalizedText(point, currentLanguage, 'name');
                    return (
                      <li key={index} className="flex justify-between text-sm">
                        <span className="flex-1 mr-2 text-left break-words">{pointName}</span>
                        <span className="flex-shrink-0">{point.users} 명</span>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
          <div className="items-center px-4 py-3">
            <button className="w-full px-4 py-2 text-base font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300" onClick={onClose}>
              {t('Dashboard.close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const [selectedGuide, setSelectedGuide] = useState(null);
  const { data, refetch } = useGetDashboardDataQuery(null, {
    pollingInterval: 3000, // Refetch every 3 seconds
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 3000);

    return () => clearInterval(interval);
  }, [refetch]);

  const chartData = useMemo(() => {
    if (!data) return [];
    return data.hourlyUsers.map((item) => ({
      time: `${item.hour}:00`,
      users: item.users,
    }));
  }, [data]);

  const topGuides = useMemo(() => {
    if (!data) return [];
    return data.topGuides;
  }, [data]);

  if (!data) return <LoadingSpinner isLoading={true} />;

  return (
    <Layout>
      <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-3">
          <OverviewCard icon={faUser} title={t('Dashboard.todayVisitors')} value={data.todayStats.visitors} bgColor="bg-yellow-200" iconColor="text-yellow-500" />
          <OverviewCard icon={faClock} title={t('Dashboard.todayUsageTime')} value={`${Helpers.formatTime(data.todayStats.usageTime, i18n.language)}`} bgColor="bg-orange-200" iconColor="text-orange-500" />
          <OverviewCard icon={faMapMarkedAlt} title={t('Dashboard.currentActiveGuides')} value={data.todayStats.activeGuides} bgColor="bg-violet-200" iconColor="text-violet-500" />
        </div>

        <div className="mb-8">
          <h2 className="mb-4 text-lg font-bold leading-6 text-gray-900">{t('Dashboard.hourlyUsers')}</h2>
          <div className="p-4 bg-white rounded-lg shadow">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={chartData} margin={{ top: 30, right: 30 }}>
                <defs>
                  <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stopColor="#5BC4FF" />
                    <stop offset="100%" stopColor="#FF5BEF" />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                <XAxis dataKey="time" tick={{ fill: '#4B5563' }} tickLine={{ stroke: '#4B5563' }} tickFormatter={(value) => value.replace(':00', '')} />
                <YAxis tick={{ fill: '#4B5563' }} tickLine={{ stroke: '#4B5563' }} />
                <Tooltip contentStyle={{ backgroundColor: '#F3F4F6', border: 'none', borderRadius: '4px' }} itemStyle={{ color: '#4B5563' }} />
                {/* <Legend wrapperStyle={{ paddingTop: '10px' }} /> */}
                <Line type="monotone" dataKey="users" stroke="url(#colorGradient)" strokeWidth={3} dot={{ r: 4, strokeWidth: 2, stroke: '#AE8FF7' }} activeDot={{ r: 6, stroke: '#AE8FF7', strokeWidth: 2, fill: 'white' }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="mb-4 text-lg font-bold leading-6 text-gray-900">{t('Dashboard.topGuides')}</h2>
          {topGuides.length > 0 ? (
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {topGuides.map((guide, index) => (
                <TopGuideCard key={guide.id} guide={guide} rank={index + 1} />
              ))}
            </div>
          ) : (
            <div className="overflow-hidden transition-all duration-300 bg-white rounded-lg shadow">
              <div className="flex p-5">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="w-5 h-5 text-red-600" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-gray-500">{t('Dashboard.noPopularGuides')}</p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold leading-6 text-gray-900">{t('Dashboard.currentActiveGuides')}</h2>
          </div>
          {data.activeGuides.length > 0 ? (
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {data.activeGuides.map((guide) => (
                <ActiveGuideCard key={guide.id} guide={guide} onCardClick={setSelectedGuide} />
              ))}
            </div>
          ) : (
            <div className="overflow-hidden transition-all duration-300 bg-white rounded-lg shadow">
              <div className="flex p-5">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="w-5 h-5 text-red-600" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-gray-500">{t('Dashboard.noActiveGuides')}</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedGuide && <GuideModal guide={selectedGuide} onClose={() => setSelectedGuide(null)} />}
      </div>
    </Layout>
  );
};

export default Dashboard;
