import React, { useState, useRef, useEffect } from 'react';
import { Info } from 'lucide-react';

const InfoTooltip = ({ content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: -8, left: 24 });

  useEffect(() => {
    if (isVisible && tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let newTop = -8;
      let newLeft = 24;

      if (tooltipRect.right > viewportWidth) {
        newLeft = -tooltipRect.width - 8;
      }

      if (tooltipRect.bottom > viewportHeight) {
        newTop = -tooltipRect.height - 8;
      }

      setTooltipPosition({ top: newTop, left: newLeft });
    }
  }, [isVisible, content]);

  return (
    <div className="relative inline-block">
      <Info className="w-4 h-4 ml-1 text-red-500 cursor-pointer hover:text-red-400" strokeWidth={3} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} />
      {isVisible && (
        <div
          ref={tooltipRef}
          className="absolute z-10 px-3 py-2 text-sm font-light text-white bg-gray-700 rounded-lg shadow-sm"
          style={{
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            maxWidth: '500px',
            minWidth: '200px',
            width: 'max-content',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          {content.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default InfoTooltip;
