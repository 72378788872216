import { apiService } from "./api";
import { selectUser } from "@store/slice/authSlice";

export const pointApi = apiService.injectEndpoints({
  endpoints: builder => ({
    newPoint: builder.mutation({
      query: (point) => ({
        url: '/points',
        method: 'POST',
        body: point,
      }),
      invalidatesTags: ['Guide'],
    }),
    getPoints: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
        const { getState } = _queryApi;
        const user = selectUser(getState());
        if (!user) {
          return { error: { status: 401, data: 'User not authenticated' } };
        }
        return baseQuery(`/users/${user.id}/guides/${_arg.guide_id}/points`);
      },
    }),
    getPoint: builder.query({
      query: (id) => `/points/${id}`,
    }),
    updatePoint: builder.mutation({
      query: ({ id, point }) => ({
        url: `/points/${id}`,
        method: 'PUT',
        body: point,
      }),
    }),
    deletePoint: builder.mutation({
      query: (id) => ({
        url: `/points/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useNewPointMutation,
  useGetPointsQuery,
  useGetPointQuery,
  useUpdatePointMutation,
  useDeletePointMutation,
} = pointApi;