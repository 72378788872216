import React from 'react';

const Modal = ({ isOpen, onClose, onCloseText, onSubmit, onSubmitText, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md overflow-hidden bg-white rounded-lg">
        <div className="p-6">
          <div className="flex items-center justify-between mb-10">
            <h2 className="text-xl font-bold">{title}</h2>
          </div>
          {children}
        </div>
        <div className="flex">
          <button onClick={onClose} className="w-1/2 py-3 text-white bg-gray-500">
            {onCloseText}
          </button>
          <button onClick={onSubmit} className="w-1/2 py-3 text-white bg-custom-personal ">
            {onSubmitText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
