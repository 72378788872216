import React, { useMemo } from 'react';
import Layout from '@components/manage/Layout';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useParams, Navigate } from 'react-router-dom';
import { useGetGuideAnalysisDataQuery } from '@services/analysisService';
import OverviewCard from '@components/manage/analysis/OverviewCard';
import UserListItem from '@components/manage/guide/analysis/UserListItem';
import LoadingSpinner from '@components/LoadingSpinner';
import Helpers from '@helpers';
import { faClock, faUser } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const GuideAnalysis = () => {
  const { guide_id } = useParams();
  const { t, i18n } = useTranslation();
  const { data, error, isLoading } = useGetGuideAnalysisDataQuery(guide_id);

  const chartData = useMemo(() => {
    if (!data) return null;

    const labels = data.dailyUsers.map((item) => new Date(item.date).toLocaleDateString(i18n.language, { month: '2-digit', day: '2-digit' }));
    const userData = data.dailyUsers.map((item) => item.users);

    return {
      labels,
      datasets: [
        {
          label: t('dailyNewUsers'),
          data: userData,
          borderColor: '#8884d8',
          tension: 0.1,
        },
      ],
    };
  }, [data, i18n.language, t]);

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  if (isLoading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-2">
          <OverviewCard icon={faUser} title={t('totalAddedUsers')} value={data.overview.totalGuides} bgColor="bg-yellow-200" iconColor="text-yellow-500" />
          <OverviewCard icon={faClock} title={t('averageUsageTime')} value={Helpers.formatTime(data.overview.usageTime, i18n.language)} bgColor="bg-orange-200" iconColor="text-orange-500" />
        </div>
        <h3 className="mb-5 text-lg font-semibold text-black">{t('dailyGuideAdditionTrend')}</h3>
        <div className="p-6 mb-8 bg-white rounded-lg shadow">{chartData && <Line data={chartData} options={chartOptions} />}</div>
        <h3 className="mb-5 text-lg font-semibold text-black ">{t('guideUserList')}</h3>
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {data.users.docs.map((user) => (
              <UserListItem
                key={user._id}
                name={user.user.email}
                status={user.viewingTime > 0 ? t('completed') : t('inProgress')}
                date={new Date(user.created).toLocaleDateString(i18n.language)}
                // duration={`${Math.round(user.usageTime / 60)} ${t('minutes')}`}
                duration={user.usageTime}
              />
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default GuideAnalysis;
