import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import benefitTitle from '@assets/images/icon_colored.svg';
import benefitIcon1 from '@assets/images/benefit_list1.png';
import benefitIcon2 from '@assets/images/benefit_list2.png';
import benefitIcon3 from '@assets/images/benefit_list3.png';
import benefitIcon4 from '@assets/images/benefit_list4.png';
import benefitIcon5 from '@assets/images/benefit_list5.png';
import benefitIcon6 from '@assets/images/benefit_list6.png';

const BenefitsSection = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const benefits = [
    { icon: benefitIcon1, title: t('home.benefits.personalExperience.title'), description: t('home.benefits.personalExperience.description') },
    { icon: benefitIcon2, title: t('home.benefits.multiLanguage.title'), description: t('home.benefits.multiLanguage.description') },
    { icon: benefitIcon3, title: t('home.benefits.costReduction.title'), description: t('home.benefits.costReduction.description') },
    { icon: benefitIcon4, title: t('home.benefits.dataInsights.title'), description: t('home.benefits.dataInsights.description') },
    { icon: benefitIcon5, title: t('home.benefits.accessibility.title'), description: t('home.benefits.accessibility.description') },
    { icon: benefitIcon6, title: t('home.benefits.remoteAccess.title'), description: t('home.benefits.remoteAccess.description') },
  ];

  return (
    <section id="benefits" ref={ref} className="flex flex-col items-center w-full gap-6 px-12 py-6 bg-white sm:py-12 md-py-15 lg:py-24">
      <div className="flex flex-col max-w-[1200px] gap-6 sm:gap-12 md:gap-15 xl:flex-row">
        <div className="flex items-baseline flex-none gap-4 break-keep">
          <img src={benefitTitle} />
          <span className="w-[165px] mb-6 text-3xl font-bold sm:text-4xl sm:mb-8">{t('home.benefits.title')}</span>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 md:gap-8">
          {benefits.map((benefit, index) => (
            <div key={index} className="p-6 transition duration-300 transform bg-gray-100 rounded-3xl sm:p-8 hover:outline-custom-personal hover:outline-2 hover:outline hover:bg-benefit-hover hover:shadow-lg">
              <div className="mb-4 w-[63px] h-[63px] bg-white rounded-full relative">
                <img src={benefit.icon} className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" />
              </div>
              <h3 className="mb-4 text-xl font-bold">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});

export default BenefitsSection;
