import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  docs: [],
  total: 0,
  limit: 10,
  page: 1,
  pages: 1
};

const guideSlice = createSlice({
  name: 'guide',
  initialState,
  reducers: {
    setGuides(state, action) {
      state.docs = action.payload.docs;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.pages = action.payload.pages;
    }
  },
});

export const { setGuides } = guideSlice.actions;

export default guideSlice.reducer;

export const selectGuides = (state) => state.guides;