import React from 'react';
import { useTranslation } from 'react-i18next';

const formatDuration = (durationString, t) => {
  const [hours, minutes] = durationString.split(':').map(Number);

  if (hours === 0) {
    return t('duration.minutes_only', { minutes });
  } else if (minutes === 0) {
    return t('duration.hours_only', { hours });
  } else {
    return t('duration.hours_and_minutes', { hours, minutes });
  }
};

const Duration = ({ duration }) => {
  const { t } = useTranslation();
  
  return formatDuration(duration, t);
};

export default Duration;