import { apiService } from "./api";

export const termsApi = apiService.injectEndpoints({
  endpoints: builder => ({
    getTerms: builder.query({
      query: (language) => `/terms?language=${language}`,
    }),
    getLatestTerms: builder.query({
      query: (language) => `/terms/latest?language=${language}`,
    }),
  }),
});

export const {
  useGetTermsQuery,
  useGetLatestTermsQuery,
} = termsApi;