import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GuideInfo from './GuideInfo';
import { Lock, Eye, ToggleRight, ToggleLeft } from 'lucide-react';
import icAnalysis from '@assets/images/ic_guide_analysis.svg';
import icQR from '@assets/images/ic_guide_qr.svg';
import icWIFI from '@assets/images/ic_guide_wifi.svg';
import icShare from '@assets/images/ic_guide_share.svg';

const GuideItem = ({ guide, onShareClick }) => {
  const { t, i18n } = useTranslation();

  const getBestTitle = (guide) => {
    const languageOrder = [i18n.language, 'en', 'ko', 'ja', 'zh'];
    for (const lang of languageOrder) {
      if (guide[`title_${lang}`]) {
        return guide[`title_${lang}`];
      }
    }
    return t('untitledGuide');
  };

  const handleShareClick = (type) => {
    onShareClick(guide._id, type);
  };

  return (
    <li className="border-b border-gray-200 last:border-b-0">
      <div className="px-4 py-7 sm:px-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0 mb-2 sm:mb-0">
            <h3 className="flex items-center mb-2 text-lg font-bold text-black truncate">
              {guide.is_private && <Lock className="w-4 h-4 mr-2 text-gray-500" aria-label={t('privateGuide')} />}
              <Link to={`/guides/${guide._id}`} className="hover:underline">
                {getBestTitle(guide)}
              </Link>
            </h3>
            <GuideInfo guide={guide} />
          </div>
          <div className="flex flex-col items-end justify-between w-full sm:justify-end sm:w-auto">
            <div className="mb-2">
              {guide.active ? (
                <span className="inline-flex items-center px-2 text-xs font-normal leading-5 text-gray-600 ">
                  <ToggleRight className="w-4 h-4 mr-1" aria-label={t('guide.active')} /> {t('guide.active')}
                </span>
              ) : (
                <span className="inline-flex items-center px-2 text-xs font-semibold leading-5 text-red-800 ">
                  <ToggleLeft className="w-4 h-4 mr-1" aria-label={t('guide.inactive')} /> {t('guide.inactive')}
                </span>
              )}
            </div>
            <div className="flex flex-wrap justify-end gap-2 sm:gap-3">
              <Link to={`/guides/${guide._id}/analysis`} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                <img src={icAnalysis} className="w-3 h-3" />
              </Link>
              <button onClick={() => handleShareClick('qr')} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                <img src={icQR} className="w-3 h-3" />
              </button>
              <button onClick={() => handleShareClick('nfc')} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                <img src={icWIFI} className="w-3 h-3" />
              </button>
              <button onClick={() => handleShareClick('code')} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                <img src={icShare} className="w-3 h-3" />
              </button>
              <Link to={`/guides/${guide._id}`} className="flex items-center p-2 bg-indigo-100 rounded-full text-custom-personal hover:text-indigo-900">
                <Eye className="w-3 h-3" />
                {/* <span className="hidden ml-1 sm:inline">{t('view')}</span> */}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default GuideItem;
