import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '@store/slice/authSlice';
import constants from '@constants';
import arrow from '@assets/images/arrow_upright.svg';
import setting from '@assets/images/ic_setting.svg';
import logo from '@assets/images/logo.svg';
import appDown from '@assets/images/download.svg';
import appDownActive from '@assets/images/download_active.svg';

const Header = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <header ref={ref} className="sticky top-0 z-10 flex justify-center px-12 py-5 text-white bg-black">
      <div className="flex flex-col w-full max-w-[1200px] gap-16 xl:flex-row">
        <div className="flex flex-col items-center justify-between w-full gap-3 header:flex-row">
          <div className="text-center sm:text-left">
            <img src={logo} />
          </div>
          <ul className="flex flex-wrap justify-center font-semibold gap-x-8 lg:text-lg sm:text-base">
            <li>
              <a
                href="#intro"
                onClick={(e) => {
                  e.preventDefault();
                  props.scrollToSection('intro');
                }}
                className="text-white transition duration-300 hover:text-indigo-600"
              >
                {t('home.nav.intro')}
              </a>
            </li>
            <li>
              <a
                href="#benefits"
                onClick={(e) => {
                  e.preventDefault();
                  props.scrollToSection('benefits');
                }}
                className="text-white transition duration-300 hover:text-indigo-600"
              >
                {t('home.nav.benefits')}
              </a>
            </li>
            <li>
              <a
                href="#reviews"
                onClick={(e) => {
                  e.preventDefault();
                  props.scrollToSection('reviews');
                }}
                className="text-white transition duration-300 hover:text-indigo-600"
              >
                {t('home.nav.reviews')}
              </a>
            </li>
            <li>
              <a
                href="#pricing"
                onClick={(e) => {
                  e.preventDefault();
                  props.scrollToSection('pricing');
                }}
                className="text-white transition duration-300 hover:text-indigo-600"
              >
                {t('home.nav.pricing')}
              </a>
            </li>
            <li>
              <a
                href="#download"
                onClick={(e) => {
                  e.preventDefault();
                  props.scrollToSection('download');
                }}
                className="flex flex-row items-center gap-1 text-white transition duration-300 hover:text-indigo-600"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {t('home.nav.download')}
                <div className="relative w-3 h-4">
                  <img src={appDown} className={`absolute top-0 left-0 transition-opacity duration-300 ${isHovered ? 'opacity-0' : 'opacity-100'}`} alt="Default Icon" />
                  <img src={appDownActive} className={`absolute top-0 left-0 transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`} alt="Hover Icon" />
                </div>
              </a>
            </li>
          </ul>
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
            <div className="flex-1 px-1 pb-2 border-b border-b-gray-500">
              <select className="w-full py-1 text-lg font-semibold text-white bg-black " defaultValue={i18n.language} onChange={(e) => changeLanguage(e.target.value)}>
                {constants.common.LANGUAGES.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
            </div>
            {isLoggedIn ? (
              <Link to="/guides" className="flex flex-row items-center w-full gap-2 px-5 py-1 text-lg font-semibold text-center text-white transition duration-300 rounded-full bg-custom-button hover:bg-indigo-100 sm:w-auto">
                {t('home.cta.dashboard')}
                <img src={setting} className="w-6 h-6" />
              </Link>
            ) : (
              <Link to="/login" className="flex flex-row items-center w-full gap-2 px-5 py-1 text-lg font-bold text-center text-white transition duration-300 rounded-full bg-custom-button hover:bg-indigo-100 sm:w-auto">
                {t('home.cta.start')}
                <img src={arrow} className="w-6 h-6" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
