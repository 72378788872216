import { apiService } from "./api";
import { selectUser } from "@store/slice/authSlice";

export const settingsApi = apiService.injectEndpoints({
  endpoints: builder => ({
    billingInformation: builder.query({
      query: () => `/payment/billing-information`,
      providesTags: ['BillingInformation'],
    }),
    issueBillingKey: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
        const { getState } = _queryApi;
        const user = selectUser(getState());
        
        if (!user) {
          return { error: { status: 401, data: 'User not authenticated' } };
        }

        return baseQuery({
          url: `/payment/issue-billing-key`,
          method: 'POST',
          body: _arg,
        });
      },
    }),
  }),
});

export const {
  useBillingInformationQuery,
  useIssueBillingKeyMutation,
} = settingsApi;
