import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Layout from '@components/account/Layout';
import { Link } from 'react-router-dom';
import Alert from '../../components/input/Alert';

function PasswordReset() {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .trim()
      .required(t('passwordRequired'))
      .min(8, t('passwordMinLength'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('passwordComplexity')
      ),
    confirmPassword: Yup.string()
      .required(t('confirmPasswordRequired'))
      .oneOf([Yup.ref('password'), null], t('passwordsMustMatch')),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    // Here you would implement the password change logic
    // For example: API call, state update, etc.
    console.log('Password change attempt', data);
  };

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-8">
          <h2 className="text-2xl font-bold mb-6 text-center">{t('setNewPassword')}</h2>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 font-bold mb-2">{t('newPassword')}</label>
              <input 
                type="password" 
                id="password" 
                {...register('password')}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500" 
              />
              {errors.password && <Alert>{errors.password.message}</Alert>}
            </div>
            <div className="mb-4">
              <label htmlFor="confirmPassword" className="block text-gray-700 font-bold mb-2">{t('confirmNewPassword')}</label>
              <input 
                type="password" 
                id="confirmPassword"
                {...register('confirmPassword')}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500" 
              />
              {errors.confirmPassword && <Alert>{errors.confirmPassword.message}</Alert>}
            </div>
            <button 
              type="submit" 
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300"
            >
              {t('changePassword')}
            </button>
          </form>
          
          <div className="mt-6 text-center">
            <p>{t('rememberPassword')} <Link to="/login" className="text-indigo-600 hover:text-indigo-800 font-semibold">{t('login')}</Link></p>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6">
          <h2 className="text-2xl font-bold mb-4">{t('passwordChangeGuidelines')}</h2>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>{t('passwordMinLengthGuideline')}</li>
            <li>{t('passwordComplexityGuideline')}</li>
            <li>{t('avoidOldPasswordGuideline')}</li>
            <li>{t('avoidPersonalInfoGuideline')}</li>
          </ul>
          <p className="mt-4 text-sm text-gray-600">
            {t('loginAfterChangeNotice')}
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default PasswordReset;